import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VideoJsPlayer } from 'video.js'
// import type { RootState } from '../../../redux/store'

export interface VideoPlayerState {
  currentTime: number
  videoInfo?: VideoInfo
  // player?: VideoJsPlayer
}

export interface VideoInfo {
  duration: number
}

const initialState: VideoPlayerState = {
  currentTime: 0,
  videoInfo: undefined,
}

export const VideoPlayerSlice = createSlice({
  name: 'videoPlayer',
  initialState,
  reducers: {
    setCurrentTime: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload
    },
    setPlayer: (state, action: PayloadAction<VideoJsPlayer | undefined>) => {
      // state.player = action.payload
    },
    setVideoInfo: (state, action: PayloadAction<VideoInfo | undefined>) => {
      state.videoInfo = action.payload
    },
  },
})

export const { setCurrentTime, setPlayer, setVideoInfo } = VideoPlayerSlice.actions

// export const selectCurrentTime = (state: RootState) => state.videoPlayer.currentTime

export default VideoPlayerSlice.reducer
