import React, { useRef } from 'react'
import { Button, CircularProgress } from '../components/shared/CommonUi'
import { CloudUploadIcon } from '../components/shared/CommonUi'
import { FileTransferProgressInfo } from './sharedServices'

type UploadHandler = (file: File) => Promise<void>

interface props {
  uploadHandler: UploadHandler
  uploading: boolean
  uploadProgress?: FileTransferProgressInfo
}

/** Encapulates file selection, upload progress display */
export const FileUploadButton = ({ uploadHandler, uploadProgress, uploading }: props) => {
  const el = useRef<HTMLInputElement>(null) // accesing input element

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]

    // do the upload right away
    if (file) {
      await uploadHandler(file)
    }
  }
  return (
    <div>
      <input type="file" ref={el} onChange={handleChange} id="contained-button-file" className="hidden" />
      <label htmlFor="contained-button-file">
        <Button onClick={() => el.current?.click()} disabled={uploading}>
          {uploading ? <CircularProgress /> : <CloudUploadIcon />}
          {uploading ? <span>Uploading</span> : <span>Select File</span>}
          {uploadProgress && <span>{uploadProgress.percentComplete.toFixed(2)}%</span>}
        </Button>
      </label>
    </div>
  )
}
