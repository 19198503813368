import React, { useReducer } from 'react'
import { Button, PrincipalLabel } from '../shared/CommonUi'
import { gql } from '@apollo/client'
import { Role, UserRoleListFragment } from '../../generated/graphql'
import { GroupSelectBox, GroupList } from '../shared/GroupSelectBox'
import { RoleSelectBox } from '../shared/RoleSelectBox'
import { NotNullable } from '../../services/utilities'

export const GRAPHQL_RESOURCES = gql`
  fragment userRoleList on UserRole {
    principal {
      __typename
      ... on User {
        id
        userName
        givenName
        familyName
        displayName
      }
      ... on Group {
        id
        name
      }
      ... on UserCategoryMember {
        userCategory
      }
    }
    role
  }
`

export const UserRoleEditor = ({
  groupList,
  value,
  onChange,
}: {
  value: NotNullable<UserRoleListFragment[]>
  onChange: (newVal: UserRoleListFragment[]) => void
  groupList?: GroupList
}) => {
  const isUserRoleValid = (userRole: Partial<UserRoleListFragment>): userRole is Required<UserRoleListFragment> =>
    Boolean(userRole?.principal && userRole?.role)
  interface State {
    newRow: NotNullable<Partial<UserRoleListFragment>>
    addError?: string
    newRowValid: boolean
  }
  type Action =
    | { type: 'add_role' }
    | { type: 'remove_role'; payload: Number }
    | { type: 'set_principal'; payload?: NotNullable<UserRoleListFragment>['principal'] }
    | { type: 'set_role'; payload?: Role }
  const reducer = (state: State, action: Action): State => {
    console.log('userRoleEditor: reducer called', action, state)
    let outState
    const addValidation = (stateIn: State): State => {
      // console.log(`adding validation`)
      return {
        ...stateIn,
        newRowValid: isUserRoleValid(stateIn.newRow),
      }
    }
    switch (action.type) {
      case 'add_role':
        if (!isUserRoleValid(state.newRow)) {
          outState = {
            ...state,
            addError: 'Invalid values',
          }
        } else {
          const newValue = [...value, { ...state.newRow }]
          console.log('new value', newValue)
          setTimeout(() => onChange(newValue), 0)
          outState = {
            ...state,
            newRow: { principal: state.newRow?.principal, role: undefined },
          }
        }
        break
      case 'remove_role':
        const newValue = value?.filter((v, index) => index !== action.payload)
        setTimeout(() => onChange(newValue), 0)
        outState = state
        break
      case 'set_principal':
        outState = {
          ...state,
          newRow: { ...state.newRow, principal: action.payload },
        }
        break
      case 'set_role':
        outState = {
          ...state,
          newRow: { ...state.newRow, role: action.payload },
        }
        break
    }
    outState = addValidation(outState)
    console.log('outState', outState)
    return outState
  }

  const [state, dispatch] = useReducer(reducer, { newRow: {}, newRowValid: false })

  return value ? (
    <>
      {/* <pre className="text-xs">{JSON.stringify(state, null, ' ')}</pre> */}
      <table className="table-fixed w-full max-w-64">
        <thead>
          <tr>
            <th className="w-1/2"></th>
            <th className="w-1/4"></th>
            <th className="w-1/4"></th>
          </tr>
        </thead>
        <tbody>
          {value.map(
            (ur, index) =>
              ur && (
                <tr className="" key={index}>
                  <td>
                    <PrincipalLabel principal={ur.principal} />
                  </td>
                  <td>
                    <div className=" font-semibold w-auto text-center">{ur.role}</div>
                  </td>
                  <td>
                    <div>
                      <Button
                        className="text-xs px-1 py-0  bg-blue-500 text-white"
                        onClick={() => dispatch({ type: 'remove_role', payload: index })}
                      >
                        Remove
                      </Button>
                    </div>
                  </td>
                </tr>
              )
          )}
          <tr>
            <td className="">
              <GroupSelectBox
                className="w-full bg-transparent hover:bg-white"
                onChange={(v) => dispatch({ type: 'set_principal', payload: v })}
                value={state.newRow.principal?.__typename === 'Group' ? state.newRow.principal : undefined}
                groupList={groupList}
              />
            </td>
            <td>
              <RoleSelectBox
                onChange={(v) => dispatch({ type: 'set_role', payload: v })}
                value={state.newRow.role}
                className="w-full bg-transparent hover:bg-white"
              />
            </td>
            <td>
              <Button
                className={`text-xs px-1 py-0  bg-blue-500 text-white ${state.newRowValid ? '' : 'hidden'}`}
                onClick={() => dispatch({ type: 'add_role' })}
              >
                Add
              </Button>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>{state.addError}</td>
          </tr>
        </tbody>
      </table>
    </>
  ) : (
    <div />
  )
}
