import React, { useState, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {
  ErrorBox,
  TextField,
  InfoField,
  PropCard,
  PropToolbar,
  PropToolbarButton,
  DropConfirmDialog,
  PropBlade,
  CheckboxField,
} from '../shared/CommonUi'
import { gql } from '@apollo/client'
import {
  EditCollCollFragment,
  useEditCollgetCollQuery,
  useEditCollUpdateCollMutation,
  UserRoleInput,
  CollectionInput,
  GroupEditorFragment,
  CollectionGroupInput,
  CollectionChrome_UserFragment,
  CollectionChrome_CollectionFragment,
} from '../../generated/graphql'
import { CollParams } from '../post/PostRoute'
import { getCollectionPath } from '../../services/routes'
import { extractPrincipalId, notEmpty, UnpackArray, NotNullable, assertUnreachable } from '../../services/utilities'
import { UserRoleEditor } from '../shared/UserRoleEditor'
import { GroupEditor } from '../shared/GroupEditor'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionHeader'

// This export isn't used directly -- GraphQL Codegen parses this
export const GRAPHQL_RESOURCES = gql`
  fragment editCollColl on Collection {
    id
    name
    unlisted
    userRoles {
      ...userRoleList
    }
    groups {
      ...groupEditor
    }
    ...CollectionChrome_Collection
  }

  query editCollgetColl($collection_id: String!) {
    getCollection(collection_id: $collection_id) {
      ...editCollColl
    }
    me {
      ...CollectionChrome_User
    }
  }

  mutation editCollUpdateColl($collection_id: String!, $inp: CollectionInput) {
    updateCollection(collection_id: $collection_id, input: $inp) {
      ...editCollColl
    }
  }
`

interface CollectionEditStateLoading {
  hasData: false
}

interface CollectionEditStateLoaded {
  hasData: true
  collection: EditCollCollFragment
  dirty: Boolean
  cleanCollection: EditCollCollFragment
  me?: CollectionChrome_UserFragment
}

type State = CollectionEditStateLoaded | CollectionEditStateLoading

interface ActionLoadData {
  type: 'load_data'
  payload: {
    data: EditCollCollFragment
    me: CollectionChrome_UserFragment | undefined
  }
}

interface ActionUpdateName {
  type: 'update_name'
  payload: string
}
interface ActionToggleUnlisted {
  type: 'toggle_unlisted'
}
interface ActionUpdateUserRoles {
  type: 'update_userRoles'
  payload: EditCollCollFragment['userRoles']
}

interface ActionUpdateGroups {
  type: 'update_groups'
  payload: GroupEditorFragment[]
}

type Action = ActionLoadData | ActionUpdateName | ActionUpdateUserRoles | ActionUpdateGroups | ActionToggleUnlisted

const reducer = (state: State, action: Action): State => {
  console.log('colledit: reducer called', action)
  const addDirty = (state: CollectionEditStateLoaded): CollectionEditStateLoaded => ({
    ...state,
    dirty: JSON.stringify(state.collection) !== JSON.stringify(state.cleanCollection),
  })

  switch (action.type) {
    case 'load_data':
      return {
        ...state,
        hasData: true,
        collection: action.payload.data,
        cleanCollection: action.payload.data,
        me: action.payload.me,
        dirty: false,
      }
    case 'update_name':
      if (!state.hasData) throw Error('Cannot set title while data is loading')
      return addDirty({
        ...state,
        collection: {
          ...state.collection,
          name: action.payload,
        },
      })
    case 'toggle_unlisted':
      if (!state.hasData) throw Error('Cannot toggle unlisted while data is loading')
      console.log('unlisted', state.collection.unlisted)
      return addDirty({
        ...state,
        collection: {
          ...state.collection,
          unlisted: !state.collection.unlisted,
        },
      })
    case 'update_userRoles':
      if (!state.hasData) throw Error('Cannot update userroles while data is loading')
      return addDirty({
        ...state,
        collection: {
          ...state.collection,
          userRoles: action.payload && [...action.payload],
        },
      })
    case 'update_groups':
      if (!state.hasData) throw Error('Cannot update groups while data is loading')
      return addDirty({
        ...state,
        collection: {
          ...state.collection,
          groups: [...action.payload],
        },
      })
  }
  assertUnreachable(action)
  throw new Error(`Unknown state passed to reducer: ${state}`)
}

export const CollectionEdit = () => {
  const { collection_id_base } = useParams<CollParams>()
  const collection_id = 'co_' + collection_id_base
  const history = useHistory()

  const { data, loading, error } = useEditCollgetCollQuery({
    variables: { collection_id },
    onCompleted: (data) => {
      console.log(`Load completel data`, data)
      loadEditState(data?.getCollection, data.me ?? undefined)
    },
  })
  const [editCollUpdateCollMutation, updUptationState] = useEditCollUpdateCollMutation({
    onCompleted: (mutData) => {
      console.log(`Save complete; data`, mutData)
      loadEditState(mutData?.updateCollection, state.hasData ? state.me : undefined)
    },
  })
  const [state, dispatch] = useReducer(reducer, { hasData: false })

  const loadEditState = (coll: EditCollCollFragment | undefined | null, me: CollectionChrome_UserFragment | undefined) => {
    console.debug(`State set`, coll)
    if (coll) dispatch({ type: 'load_data', payload: { data: coll, me: me } })
  }

  // handles showing the dirty save warning dialog
  const [dirtyClosing, setDirtyClosing] = useState(false)

  const handleClose = (force: boolean) => {
    if (state.hasData && state.dirty && !force) {
      setDirtyClosing(true)
    } else {
      history.push(getCollectionPath(collection_id))
    }
  }

  const handleSave = () => {
    if (!state.hasData) return
    // map the full query/edit structure into the lighter input structure
    const colImp: CollectionInput = {
      name: state.collection.name,
      unlisted: state.collection.unlisted,
    }
    if (state.collection.userRoles) {
      colImp.userRoles = state.collection.userRoles.filter(notEmpty).map<UserRoleInput>((ur) => ({
        principal_id: extractPrincipalId(ur?.principal),
        role: ur?.role,
      }))
    }
    if (state.collection.groups) {
      colImp.groups = state.collection.groups.filter(notEmpty).map<CollectionGroupInput>((g) => ({
        id: g.id,
        name: g.name,
        users: g.users.filter(notEmpty).map((user) => ({ id: user.id })),
      }))
    }
    console.log(`collImp`, colImp)
    editCollUpdateCollMutation({
      variables: {
        collection_id,
        inp: colImp,
      },
    })
  }

  if (error) {
    return <ErrorBox message={error.message} />
  }

  if (!state.hasData) {
    return <div>Loading...</div>
  }
  const collection = state.collection
  const me = state.me

  return (
    <CollectionChrome collection={collection} navPage={NavPage.Settings} me={me ?? null}>
      <form>
        <PropBlade>
          <PropToolbar>
            <PropToolbarButton className={state.dirty ? '' : 'hidden'} onClick={handleSave}>
              Save Changes
            </PropToolbarButton>
            <PropToolbarButton onClick={() => handleClose(false)}>Close</PropToolbarButton>
            <DropConfirmDialog
              visible={dirtyClosing}
              onCancel={() => setDirtyClosing(false)}
              onConfirm={() => handleClose(true)}
              title="Unsaved changes"
              message="Changes will be lost without saving"
            />
          </PropToolbar>
          <PropCard title="Collection Information">
            <InfoField label="id" value={state.collection.id} />
            <TextField
              id="titleField"
              label="Title"
              name="name"
              value={state.collection.name ?? ''}
              onChange={(ev) => dispatch({ type: 'update_name', payload: ev.currentTarget.value })}
            />
            <CheckboxField
              id="unlistedField"
              label="Unlisted"
              name="name"
              checked={state.collection.unlisted ?? undefined}
              onChange={(ev) => dispatch({ type: 'toggle_unlisted' })}
            />
          </PropCard>
          <PropCard title="Collection Roles">
            {state.collection.userRoles && (
              <UserRoleEditor
                value={state.collection.userRoles ? state.collection.userRoles.filter(notEmpty) : []}
                onChange={(ev) => dispatch({ type: 'update_userRoles', payload: ev })}
                groupList={data?.getCollection?.groups?.filter(notEmpty)}
              />
            )}
          </PropCard>
          <PropCard title="Groups">
            {state.collection.userRoles && (
              <GroupEditor
                collectionID={state.collection.id}
                value={state.collection.groups?.filter(notEmpty) ?? []}
                onChange={(ev) => dispatch({ type: 'update_groups', payload: ev })}
              />
            )}
          </PropCard>
        </PropBlade>
        {/* <pre className="text-xs">{JSON.stringify(state?.collection, null, ' ')}</pre> */}
      </form>
    </CollectionChrome>
  )
}
