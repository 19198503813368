import React, { SyntheticEvent } from 'react'
import { Role } from '../../generated/graphql'

type ValueOf<T> = T[keyof T]
const NullKey = 'NULL'

const isRoleKey = (test: string): test is keyof typeof Role => {
  return Object.keys(Role).find((ro) => ro === test) !== undefined
}

const parseRoleEum: (input: string) => Role | undefined = (input: string) => {
  if (input === null || input === undefined) return undefined
  const thisKey = Object.keys(Role).find((ro) => (Role as any)[ro] === input)
  return thisKey ? (Role as any)[thisKey] : undefined
}

export type PossibleSelection = Role | undefined
export interface RoleSelectBoxProps {
  onChange?: (selection: PossibleSelection) => void
  value?: Role | undefined | null
  className?: string
}
export const RoleSelectBox = ({ onChange, value, className }: RoleSelectBoxProps) => {
  const handeleOnChange = (ev: SyntheticEvent<HTMLSelectElement>) => {
    // console.log(`select`, ev)
    // console.log(`role`, isRoleKey(ev.currentTarget.value) && ev.currentTarget.value)
    onChange && onChange(parseRoleEum(ev.currentTarget.value))
  }
  // console.log(`role value`, value)
  return (
    <div>
      <select onChange={handeleOnChange} value={value ?? NullKey} className={className}>
        <option key={NullKey} value={''}>
          Select
        </option>
        {Object.entries(Role)?.map((g) => (
          <option key={g[1]} value={g[1]}>
            {g[1]}
          </option>
        ))}
      </select>
    </div>
  )
}
