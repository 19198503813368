import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { APPLICATION_INSIGHTS_IKEY } from './config'

const history = createBrowserHistory({})
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APPLICATION_INSIGHTS_IKEY,
    extensions: [reactPlugin],
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
})
appInsights.loadAppInsights()
console.log(`App Insights initialized with key ${APPLICATION_INSIGHTS_IKEY}`)
export { reactPlugin, appInsights }
