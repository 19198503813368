import React from 'react'
import PostRoute from './components/post/PostRoute'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from './apolloClient'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './services/authProvider'
import { CollectionsRoute } from './components/collections/collectionList'
import { AppInsightsContext, withAITracking } from '@microsoft/applicationinsights-react-js'
import { reactPlugin } from './services/deps'

function App() {
  return (
    // <AppInsightsErrorBoundary onError={() => <h1>something went wrong; the error has been reported</h1>} appInsights={reactPlugin}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <ApolloProvider client={apolloClient}>
            <Router>
              <CollectionsRoute />
              <PostRoute />
            </Router>
        </ApolloProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
    // </AppInsightsErrorBoundary>
  )
}

export default withAITracking(reactPlugin, App)
