import React, { useState } from 'react'
import { Route, Switch, Link as RouterLink, useHistory } from 'react-router-dom'
import {
  useGetCollectionsListQuery,
  useCreateCollectionMutation,
  Role,
  useCollectionCreate_BaseQuery,
} from '../../generated/graphql'
import { Button, AddIcon, TextField } from '../shared/CommonUi'
import { getCollectionPath } from '../../services/routes'
import { CollectionView } from './collectionView'
import { PostEdit } from '../post/PostEdit'
import { PostView } from '../post/PostView'
import { CollectionEdit } from './collectionEdit'
import { gql } from '@apollo/client'
import { containsAny } from '../../services/utilities'
import { RootChrome } from '../shared/RootChrome'
import { PlaylistListPage } from '../playlist/PlaylistListPage'
import { PlaylistViewPage } from '../playlist/PlaylistViewPage'
import { PlaylistPostPage } from '../playlist/PlaylistPostPage'
import { PlaylistEdit } from '../playlist/PlaylistEdit'
import { AssignmentListPage } from '../assignments/AssignmentListPage'

export interface CollectionCardProps {
  collection?: { id: string; name: string } | null
}
export const CollectionCard = ({ collection }: CollectionCardProps) => (
  <RouterLink to={getCollectionPath(collection?.id)}>
    <div className="border-2 border-gray-700 m-2 h-12 p-2 text-lg rounded-sm shadow-sm">
      <div>{collection ? collection.name : <span>Loading...</span>} </div>
    </div>
  </RouterLink>
)

export const GQL_DEFINITIONS = gql`
  query getCollectionsList {
    getCollections {
      id
      name
      myRoles
    }
    myRoles
    me {
      ...CollectionChrome_User
    }
  }
`

export const CollectionsList = () => {
  const { data, loading, error } = useGetCollectionsListQuery({})

  const displayCollections = loading || !data?.getCollections ? new Array(5).map((_) => null) : data.getCollections

  const me = data?.me ?? null

  return (
    <RootChrome me={me}>
      {error ? (
        <div>{error.message}</div>
      ) : displayCollections?.length === 0 ? (
        data?.me === null ? (
          <div>You are not logged in. Please log in to see any collections assigned to you.</div>
        ) : (
          <div>You have no video collections assigned to you.</div>
        )
      ) : (
        displayCollections?.map((coll, index) => <CollectionCard key={index} collection={coll} />)
      )}
      {containsAny(data?.myRoles, [Role.Admin]) ? (
        <Button to="/c/create" color="primary">
          <AddIcon />
          <span>Create Collection</span>
        </Button>
      ) : null}
    </RootChrome>
  )
}

export const CollectionCreate = () => {
  const [newCollectionName, setNewCollectionName] = useState<string>('')
  const history = useHistory()
  const { data, loading } = useCollectionCreate_BaseQuery()
  const [createCollectionMutation] = useCreateCollectionMutation({
    onCompleted: (data) => {
      const id = data.createCollection?.id
      if (id) history.push(getCollectionPath(id))
    },
  })

  const handleCollectionCreateClick = () => {
    createCollectionMutation({
      variables: {
        input: { name: newCollectionName },
      },
    })
  }

  const me = data?.me ?? null

  return (
    <RootChrome me={me}>
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          handleCollectionCreateClick()
        }}
      >
        <TextField
          id="standard-basic"
          label="container name"
          value={newCollectionName}
          onChange={(e) => setNewCollectionName(e.currentTarget.value)}
        />

        <Button type="submit" className="">
          Create Collection
        </Button>
      </form>
    </RootChrome>
  )
}

CollectionCreate.queries = {
  base: gql`
    query CollectionCreate_base {
      me {
        ...RootChrome_User
      }
    }
  `,
}

export const CollectionsRoute = () => (
  <Switch>
    <Route path={`/c/create`} component={CollectionCreate} />
    <Route path={`/`} component={CollectionsList} exact />
    {/* <Route path={`${match?.path}/:collection_id_base`} component={CollectionView} exact /> */}
    <Route
      path={`/c/:collection_id_base`}
      children={({ match }) => (
        <Switch>
          <Route path={`${match?.path}/edit`} component={CollectionEdit} />
          <Route path={`${match?.path}/playlists/:playlist_id_base/edit`} component={PlaylistEdit} />
          <Route path={`${match?.path}/playlists/:playlist_id_base/:post_index_str`} component={PlaylistPostPage} />
          <Route path={`${match?.path}/playlists/:playlist_id_base`} component={PlaylistViewPage} />
          <Route path={`${match?.path}/playlists`} component={PlaylistListPage} />
          <Route path={`${match?.path}/assignments`} component={AssignmentListPage} />
          <Route path={`${match?.path}/p/:post_id/edit`} component={PostEdit} />
          <Route path={`${match?.path}/p/:post_id`} component={PostView} />
          <Route path={`${match?.path}/`} component={CollectionView} />
          <Route path={`${match?.path}?s=:search_string?`} component={CollectionView} />
        </Switch>
      )}
    />
  </Switch>
)
