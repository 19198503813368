import { gql } from '@apollo/client'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQueryState } from 'use-location-state'
import { PlaylistCard_PlaylistFragment, PlaylistTable_PlaylistFragment, usePlaylistList_BaseQuery } from '../../generated/graphql'
import { notEmpty } from '../../services/utilities'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionNav'
import { Button, CircularProgress, ErrorBox, Tdl } from '../shared/CommonUi'
import { sortby } from '../../services/utilities'
import { getCollectionPathPlaylistView } from '../../services/routes'
import { DateTime } from 'luxon'

export interface PlaylistCardProps {
  playlist: PlaylistCard_PlaylistFragment
  collection_id: string
}

const displayDate = (date: string) =>
  DateTime.fromISO(date).toLocaleString({
    timeZoneName: 'short',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

export const PlaylistCard = (props: PlaylistCardProps) => {
  const { playlist, collection_id } = props
  const link = getCollectionPathPlaylistView(collection_id, playlist.id)
  const AvLink = ({ label, date }: { label: string; date: string }) => (
    <span className="px-2">
      {label}: <span className="font-normal">{displayDate(date)}</span>
    </span>
  )
  return (
    <tr>
      <Tdl to={playlist.isAvailableMe ? link : undefined}>
        <div>{playlist.title}</div>
        <div className={'pl-2 text-sm text-gray-500 font-thin ' + (playlist.isAvailable ? '' : 'text-red-600')}>
          {playlist.availableStartDate && <AvLink label="Available" date={playlist.availableStartDate} />}
          {playlist.availableEndDate && <AvLink label="Expires" date={playlist.availableEndDate} />}
        </div>
      </Tdl>
      <Tdl to={link}>{playlist.numPosts}</Tdl>
    </tr>
  )
}

PlaylistCard.fragments = {
  playlist: gql`
    fragment PlaylistCard_Playlist on Playlist {
      id
      title
      numPosts
      isAvailable
      isAvailableMe
      availableStartDate
      availableEndDate
    }
  `,
}

export interface PlaylistTableProps {
  playlists: PlaylistTable_PlaylistFragment[]
  collection_id: string
}

export const PlaylistTable = (props: PlaylistTableProps) => {
  const { playlists, collection_id } = props
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Entry Count
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {playlists.sort(sortby('title')).map((e) => (
                  <PlaylistCard key={e.id} playlist={e} collection_id={collection_id} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

PlaylistTable.fragments = {
  playlist: gql`
    fragment PlaylistTable_Playlist on Playlist {
      ...PlaylistCard_Playlist
    }
  `,
}

export interface CollectionViewParams {
  collection_id_base: string
}

function lastItem<T>(inArray?: T[]): T | undefined {
  if (!inArray || !inArray.length) return undefined
  return inArray[inArray.length - 1]
}

export interface PlaylistListPageProps {}

export const PlaylistListPage = (props: PlaylistListPageProps) => {
  const pagePlaylists = (direction: 'f' | 'b') => {
    const args: { limit: number; after: string | null; before: string | null } = { limit: pageSize, after: null, before: null }
    if (direction === 'f') {
      const cursor = edges && lastItem(edges)?.cursor
      console.log(`new cursor: ${cursor}, edges`, edges)
      args.after = cursor || null
      setPageAfter(cursor || '')
      setPageBefore('')
    } else {
      const cursor = edges && edges[0]?.cursor
      args.before = cursor || null
      setPageBefore(cursor || '')
      setPageAfter('')
    }
    // refetch(args)
  }
  const history = useHistory()
  const { collection_id_base } = useParams<CollectionViewParams>()
  const collection_id = `co_${collection_id_base}`
  const [pageAfter, setPageAfter] = useQueryState('pa', '')
  const [pageBefore, setPageBefore] = useQueryState('pb', '')
  const pageSize = 20
  const { data, loading, error } = usePlaylistList_BaseQuery({
    variables: {
      collection_id,
      after: pageAfter,
      before: pageBefore,
      limit: pageSize,
    },
  })
  if (!data?.getCollection) {
    if (loading) return <div>Loading...</div>
    if (error) {
      if (error.message.indexOf('Permission denied') > -1) {
        history.push('/')
      } else {
        return <ErrorBox message={'PERMD:' + error.message} />
      }
      return <div>Error: ${error.message}</div>
    }
    return <div>Error loading playlist data for collection {collection_id}</div>
  }
  const collection = data.getCollection
  const pageInfo = data.getCollection.playlists?.pageInfo
  const me = data.me || null
  const edges = collection.playlists?.edges

  const playlists = collection.playlists?.edges && collection.playlists?.edges?.map((e) => e && e.node)?.filter(notEmpty)
  return (
    <CollectionChrome collection={collection} me={me} navPage={NavPage.Playlists}>
      {playlists ? (
        <>
          <PlaylistTable playlists={playlists} collection_id={collection.id} />
          <div className="flex space-x-4 p-4">
            <Button type="button" onClick={() => pagePlaylists('b')} disabled={!pageInfo?.hasPreviousPage}>
              Prev
            </Button>
            <Button type="button" onClick={() => pagePlaylists('f')} disabled={!pageInfo?.hasNextPage}>
              Next
            </Button>
          </div>
        </>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <div>No playlists found</div>
      )}
    </CollectionChrome>
  )
}

PlaylistListPage.fragments = {
  playlist: gql`
    fragment PlaylistList_Playlist on Playlist {
      ...PlaylistCard_Playlist
    }
  `,
  collection: gql`
    fragment PlaylistList_Collection on Collection {
      ...CollectionChrome_Collection
    }
  `,
  playlists: gql`
    fragment PlaylistListPage_PlaylistPage on PlaylistPage {
      edges {
        node {
          ...PlaylistTable_Playlist
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
  `,
}

PlaylistListPage.queries = {
  Base: gql`
    query PlaylistList_Base($collection_id: String!, $limit: Int, $after: String, $before: String) {
      getCollection(collection_id: $collection_id) {
        ...PlaylistList_Collection
        playlists(limit: $limit, after: $after, before: $before) {
          ...PlaylistListPage_PlaylistPage
        }
      }
      me {
        ...CollectionChrome_User
      }
    }
  `,
}
