import { gql } from '@apollo/client'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { usePlaylistView_BaseQuery } from '../../generated/graphql'
import {
  getCollectionPathPlaylists,
  getCollectionPathPlaylistView,
  getPlaylistEditPath,
  playlistEntry,
} from '../../services/routes'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionHeader'
import { Button, ChevronLeft, Tdl } from '../shared/CommonUi'

export interface PlaylistViewParams {
  collection_id_base: string
  playlist_id_base: string
}

export const PlaylistViewPage = () => {
  const { collection_id_base, playlist_id_base } = useParams<PlaylistViewParams>()
  const history = useHistory()
  const collection_id = `co_${collection_id_base}`
  const playlist_id = `pl_${playlist_id_base}`
  const { data, loading, error } = usePlaylistView_BaseQuery({
    variables: {
      collection_id,
      playlist_id,
    },
  })
  const collection = data?.getCollection
  const playlist = data?.getCollection?.getPlaylist
  if (!collection || !playlist) {
    if (loading) return <div>Loading...</div>
    if (error) return <div>Error: ${error.message}</div>
    return <div>Error loading playlist data for collection {collection_id}</div>
  }

  const me = data?.me || null

  const BreadcrumbDivider = (props: { className?: string }) => (
    <li className={props.className}>
      <span className="mx-2">/</span>
    </li>
  )

  const handleEditClick = () => {
    history.push(getPlaylistEditPath(collection_id)(playlist_id))
  }

  return (
    <CollectionChrome collection={collection} me={me} navPage={NavPage.Playlists}>
      <nav className="bg-grey-light font-sans w-full p-2 flex flex-row justify-between items-center">
        <ol className="list-reset flex text-grey-dark ">
          <li className="lg:hidden pr-2">
            <Link to={getCollectionPathPlaylists(collection_id)}>
              <ChevronLeft />
            </Link>
          </li>
          <li className="hidden lg:inline">
            <Link to={getCollectionPathPlaylists(collection_id)}>Playlists</Link>
          </li>
          <BreadcrumbDivider className="hidden lg:inline" />
          <li className="font-semibold">
            <Link to={getCollectionPathPlaylistView(collection_id, playlist_id)}>{playlist.title}</Link>
          </li>
          {playlist.myRoles.find((r) => r === 'ADMIN') ? (
            <li>
              <Button onClick={handleEditClick}>Edit</Button>
            </li>
          ) : null}
        </ol>
      </nav>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      #
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Title
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {playlist.posts
                    ?.map((po) => ({ ...po, linkUrl: playlistEntry(collection.id, playlist_id, po.index) }))
                    .map((po) => (
                      <tr className="h-16" key={po.index}>
                        <Tdl className="px-6 py-4 whitespace-nowrap" to={po.linkUrl}>
                          <div className="text-sm text-gray-900">{po.index + 1}</div>
                        </Tdl>
                        <Tdl className="px-0 py-0 whitespace-nowrap flex flex-row" to={po.linkUrl}>
                          <div className="h-16 w-28 bg-gray-700 flex-shrink-0">
                            {po.post.primaryPoster?.findFormat?.sourceUrl ? (
                              <img
                                className="h-full w-full"
                                loading="lazy"
                                src={po.post.primaryPoster?.findFormat?.sourceUrl}
                                alt=""
                              />
                            ) : (
                              <div className="border-2 border-gray-800 h-full w-full"></div>
                            )}
                          </div>
                        </Tdl>
                        <Tdl className="px-6 py-4 whitespace-nowrap" to={po.linkUrl}>
                          <div className="text-sm text-gray-900">{po.post.title}</div>
                        </Tdl>
                        {/* <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Active
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Admin</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Edit
                        </a>
                      </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CollectionChrome>
  )
}

PlaylistViewPage.queries = {
  Base: gql`
    query PlaylistView_Base($collection_id: String!, $playlist_id: String!) {
      getCollection(collection_id: $collection_id) {
        ...CollectionChrome_Collection
        getPlaylist(playlist_id: $playlist_id) {
          id
          title
          myRoles
          posts {
            index
            post {
              id
              title
              primaryPoster {
                findFormat(preferredHeight: 40) {
                  sourceUrl
                  width
                  height
                }
              }
            }
          }
        }
      }
      me {
        ...CollectionChrome_User
      }
    }
  `,
}
