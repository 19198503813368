export const trimType = (longId: string) => {
  const splitID = longId.split('_')
  return splitID[1]
}

export const getCollectionPath = (collection_id?: string) => {
  if (!collection_id) return ''
  return `/c/${trimType(collection_id)}`
}

export const getCollectionPathEdit = (collection_id: string) => `${getCollectionPath(collection_id)}/edit`
export const getCollectionPathPlaylists = (collection_id: string) => `${getCollectionPath(collection_id)}/playlists`
export const getCollectionPathAssignments = (collection_id: string) => `${getCollectionPath(collection_id)}/assignments`
export const getCollectionPathPlaylistView = (collection_id: string, playlist_id: string) =>
  `${getCollectionPathPlaylists(collection_id)}/${trimType(playlist_id)}`
export const playlistEntry = (collection_id: string, playlist_id: string, playlist_index: number) =>
  `${getCollectionPathPlaylistView(collection_id, playlist_id)}/${playlist_index}`

export const getCollectionListPath = () => `/`

export const getPostPath = (collection_id: string) => (post_id: string) => {
  if (!post_id) return ''
  const base = getCollectionPath(collection_id)
  return `${base}/p/${trimType(post_id)}`
}

export const getPostPathEdit = (collection_id: string) => (post_id: string) => {
  if (!post_id) return ''
  return getPostPath(collection_id)(post_id) + '/edit'
}

export const getPlaylistPath = (collection_id: string) => (playlist_id: string) => {
  if (!playlist_id) return ''
  const base = getCollectionPath(collection_id)
  return `${base}/playlists/${trimType(playlist_id)}`
}

export const getPlaylistEditPath = (collection_id: string) => (playlist_id: string) => {
  const base = getPlaylistPath(collection_id)(playlist_id)
  return `${base}/edit`
}

const routes = {
  getCollectionPath,
  getCollectionListPath,
  getPostPath,
  getPostPathEdit,
}

export default routes
