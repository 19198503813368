import { Route, Switch } from 'react-router-dom'
import React from 'react'

import { PostEdit } from './PostEdit'
import { PostView } from './PostView'

export interface PostParams {
  post_id: string
  collection_id_base: string
}

export interface CollParams {
  collection_id_base: string
}

const PostRoute = () => (
  <Route
    path="/p"
    children={({ match }) => (
      <Switch>
        <Route path={`${match?.path}/:id/edit`} component={PostEdit} />
        <Route path={`${match?.path}/:id`} component={PostView} />
      </Switch>
    )}
  />
)


export default PostRoute
