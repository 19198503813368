import React from 'react'
import { PostParams } from './PostRoute'
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom'
import { useGetPostViewQuery, Role, PostTitleBlock_PostFragment, PostVideoBlock_PostFragment } from '../../generated/graphql'
import { Button, ErrorBox } from '../shared/CommonUi'
import { getPostPathEdit, getCollectionPath } from '../../services/routes'
import { containsAny } from '../../services/utilities'
import { NavPage } from '../shared/CollectionHeader'
import { CollectionChrome } from '../shared/CollectionChrome'
import { gql } from '@apollo/client'
import { PostVideoBlock } from './PostVideoBlock'
import { useLocation } from 'react-router-dom'
import { useQueryState } from 'use-location-state'
import queryString from 'query-string'

interface PostTitleBlockProps {
  post?: PostTitleBlock_PostFragment | null
  loading: boolean
}
export function PostTitleBlock({ post }: PostTitleBlockProps) {
  return post ? (
    <>
      <h3 className="text-2xl">{post?.title}</h3>
      <h4 className="text-md">
        <RouterLink to={getCollectionPath(post?.collection.id)}>{post?.collection.name}</RouterLink>
      </h4>
    </>
  ) : (
    <div>No Data</div>
  )
}

PostTitleBlock.fragments = {
  Post: gql`
    fragment PostTitleBlock_Post on Post {
      id
      title
      collection {
        id
        name
      }
    }
  `,
}

export function PostView() {
  const { post_id: post_id_base, collection_id_base } = useParams<PostParams>()
  // const [embed] = useQueryState('embed', '')
  const location = useLocation()
  const { embed, t: accessTicket } = queryString.parse(location.search)
  const isEmbedded = Boolean(Number(embed))
  const post_id = 'po_' + post_id_base
  const collection_id = 'co_' + collection_id_base
  const { loading, error, data } = useGetPostViewQuery({
    variables: { collection_id, post_id },
    fetchPolicy: 'cache-and-network',
    context: { accessTicket },
  })
  const history = useHistory()

  const handleEditClick = () => {
    history.push(getPostPathEdit(collection_id)(post_id))
  }

  if (loading && !data?.getCollection) {
    return <div>Loading...</div>
  }
  if (error) {
    return <ErrorBox message={error.message} />
  }
  if (!data?.getCollection?.post) {
    return <ErrorBox message={`Collection ${collection_id} not found`} />
  }

  const collection = data?.getCollection
  const me = data.me
  const post = data.getCollection?.post

  const postBlock = <PostVideoBlock post={post} loading={loading} embed={isEmbedded} />
  console.log('isEmbedded', isEmbedded)

  return isEmbedded ? (
    postBlock
  ) : (
    <CollectionChrome collection={collection} navPage={NavPage.Entries} me={me ?? null}>
      <>
        {postBlock}
        <PostTitleBlock post={post} loading={loading} />

        {containsAny(post.myRoles, [Role.Admin, Role.Creator, Role.Editor, Role.MediaManager]) && (
          <Button className="bg-blue-500 text-white p-2" onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </>
    </CollectionChrome>
  )
}

PostView.fragments = {
  Post: gql`
    fragment GetPostView_Post on Post {
      id
      title
      myRoles
      ...PostVideoBlock_Post
      ...PostTitleBlock_Post
    }
  `,
  Collection: gql`
    fragment GetPostView_Collection on Collection {
      id
      name
      myRoles
    }
  `,
}

PostView.queries = {
  getPostView: gql`
    query getPostView($collection_id: String!, $post_id: String!) {
      getCollection(collection_id: $collection_id) {
        ...GetPostView_Collection
        post(post_id: $post_id) {
          ...GetPostView_Post
        }
      }
      me {
        ...CollectionChrome_User
      }
    }
  `,
}
