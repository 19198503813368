import { gql } from '@apollo/client'
import React, { useReducer, useState } from 'react'
import { PostEdit_EvaluationsPanel_PostFragment, PostEdit_EvaluationsPanel_QueryFragment } from '../../../generated/graphql'
import { EvaluationTypeSelector } from '../../evaluation/EvaluationTypeSelector'
import { InfoField, PropCard, PropToolbarButton } from '../../shared/CommonUi'

export const GRAPHQL_RESOURCES = gql`
  fragment postEdit_evaluationsPanel_post on Post {
    evaluations {
      id
      author {
        id
        userName
        givenName
        familyName
      }
      evaluationType {
        key
        name
      }
      status
    }
  }
  fragment postEdit_evaluationsPanel_query on Query {
    evaluationTypes {
      key
      name
    }
  }
`

export interface State {}

export interface EvaluationBladeProps {
  post: PostEdit_EvaluationsPanel_PostFragment
  query: PostEdit_EvaluationsPanel_QueryFragment
}

export const EvaluationsBlade = () => (
  <PropCard
    title="Evaluations"
    titleContent={
      <>
        <EvaluationTypeSelector />
        <PropToolbarButton>+ New</PropToolbarButton>
      </>
    }
  >
    <InfoField label="Review" value="Content"></InfoField>
  </PropCard>
)
