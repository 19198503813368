import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { CollectionHeader } from './CollectionHeader'
import { CollectionNav, NavPage } from './CollectionNav'
import { CollectionChrome_CollectionFragment, CollectionChrome_UserFragment, useCreatePostMutation } from '../../generated/graphql'
import { useHistory } from 'react-router-dom'
import { getPostPathEdit } from '../../services/routes'

export interface Props {
  children?: React.ReactNode
  collection: CollectionChrome_CollectionFragment
  navPage: NavPage
  me: CollectionChrome_UserFragment | null
  searchString?: string
  onSearch?: (searchString: string) => void
}

export const CollectionChrome = (props: Props) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)
  const toggleMobileTabVisible = () => setMobileNavVisible(!mobileNavVisible)
  return (
    <div
      className="flex h-screen bg-gray-50 dark:bg-gray-900"
      // :class="{ 'overflow-hidden': isSideMenuOpen }"
    >
      <div className="flex flex-col flex-1 w-full">
        <CollectionHeader
          toggleMobileNavVisible={toggleMobileTabVisible}
          collection={props.collection}
          me={props.me}
          navPage={props.navPage}
          onSearch={props.onSearch}
          searchString={props.searchString}
        />
        {props.children}
      </div>
    </div>
  )
}

CollectionChrome.fragments = {
  collection: gql`
    fragment CollectionChrome_Collection on Collection {
      ...CollectionNav_Collection
      ...CollectionHeader_Collection
    }
  `,
  me: gql`
    fragment CollectionChrome_User on User {
      ...CollectionHeader_User
    }
  `,
}
