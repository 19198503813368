import React, { Ref, BaseHTMLAttributes, SelectHTMLAttributes } from 'react'
import { Group } from '../../generated/graphql'

export type GroupPartial = Pick<Required<Group>, 'id' | 'name' | '__typename'>
export type GroupList = GroupPartial[]
type PossibleSelection = GroupPartial | undefined

interface GroupSelectBoxProps {
  groupList?: GroupList
  className?: string
  onChange: (value: PossibleSelection) => void
  value: PossibleSelection
}
export const GroupSelectBox = ({  className, groupList, onChange, value }: GroupSelectBoxProps) => {
  return (
    <div>
      <select
      className={className}
        defaultValue={value?.id}
        onChange={(ev) => onChange && onChange(groupList?.find((it) => it.id === ev.currentTarget.value ?? ''))}
      >
        <option value={''} key={''}>
          Select
        </option>
        {groupList?.map((g) => (
          <option value={g.id} key={g.id}>
            {g.name}
          </option>
        ))}
      </select>
    </div>
  )
}
