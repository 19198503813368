import React from 'react'
import { NavPage } from '../shared/CollectionHeader'
import { CollectionChrome } from '../shared/CollectionChrome'
import { getCollectionPath, getCollectionPathPlaylistView, playlistEntry } from '../../services/routes'
import { Link, useParams } from 'react-router-dom'
import { gql } from '@apollo/client'
import { usePlaylistPostPage_BaseQuery } from '../../generated/graphql'
import { Button, ChevronLeft, CircularProgress } from '../shared/CommonUi'
import { PostVideoBlock } from '../post/PostVideoBlock'

export interface PlaylistViewPostPageParams {
  collection_id_base: string
  playlist_id_base: string
  post_index_str: string
}

export const PlaylistPostPage = () => {
  const { collection_id_base, playlist_id_base, post_index_str } = useParams<PlaylistViewPostPageParams>()
  const collection_id = `co_${collection_id_base}`
  const playlist_id = `pl_${playlist_id_base}`
  const post_index = Number(post_index_str)

  const { data, loading, error } = usePlaylistPostPage_BaseQuery({
    variables: {
      collection_id,
      playlist_id,
      post_index,
    },
  })
  const collection = data?.getCollection
  const playlist = collection?.getPlaylist
  const postEntry = playlist?.getPostByIndex
  const post = postEntry?.post

  if (!collection) {
    if (loading) return <div>Loading...</div>
    if (error) return <div>Error: ${error.message}</div>
    return <div>Error loading playlist data for collection {collection_id}</div>
  }

  const me = data?.me || null

  const BreadcrumbDivider = () => (
    <li className="hidden lg:inline">
      <span className="mx-2">/</span>
    </li>
  )

  return (
    <CollectionChrome collection={collection} me={me} navPage={NavPage.Playlists}>
      {!playlist || !post || !postEntry ? (
        <CircularProgress />
      ) : (
        <>
          <nav className="bg-grey-light font-sans w-full p-2 flex flex-row justify-between items-center">
            <ol className="list-reset flex text-grey-dark ">
              <li className="lg:hidden pr-2">
                <Link to={getCollectionPathPlaylistView(collection_id, playlist_id)}>
                  <ChevronLeft />
                </Link>
              </li>
              <li className="hidden lg:inline">
                <Link to={getCollectionPath(collection_id)}>Playlists</Link>
              </li>
              <BreadcrumbDivider />
              <li className="hidden lg:inline font-semibold">
                <Link to={getCollectionPathPlaylistView(collection_id, playlist_id)}>{playlist.title}</Link>
              </li>
              <li className="hidden lg:inline">
                <span className="mx-2">/</span>
              </li>
              <li className="font-semibold overflow-x-hidden">{post.title}</li>
            </ol>
            <div className="flex items-center">
              <Button
                className="px-2 "
                to={
                  typeof postEntry.prev_index === 'number' ? playlistEntry(collection.id, playlist_id, postEntry.prev_index) : '#'
                }
                disabled={!postEntry.prev_index}
              >
                Prev
              </Button>
              <div className="px-1">
                {postEntry.index + 1}/{playlist.numPosts}
              </div>
              <Button
                className="px-2"
                to={postEntry.next_index ? playlistEntry(collection.id, playlist_id, postEntry.next_index) : '#'}
                disabled={!postEntry?.next_index}
              >
                Next
              </Button>
            </div>
          </nav>
          <PostVideoBlock post={post} loading={false} />
        </>
      )}
    </CollectionChrome>
  )
}

PlaylistPostPage.queries = {
  base: gql`
    query PlaylistPostPage_Base($collection_id: String!, $playlist_id: String!, $post_index: Int!) {
      getCollection(collection_id: $collection_id) {
        ...CollectionChrome_Collection
        name
        getPlaylist(playlist_id: $playlist_id) {
          id
          title
          numPosts
          getPostByIndex(post_index: $post_index) {
            post {
              id
              title
              ...PostVideoBlock_Post
            }
            next_index
            prev_index
            index
          }
        }
      }
      me {
        ...CollectionChrome_User
      }
    }
  `,
}
