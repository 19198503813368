import { gql } from '@apollo/client'
import React from 'react'
import { PostVideoBlock_PostFragment } from '../../generated/graphql'
import VideoPlayer, { VideoSource } from '../shared/videoPlayer/VideoPlayer'

export interface PostVideoBlockProps {
  loading: boolean
  post?: PostVideoBlock_PostFragment
  embed?: boolean
}

const BlankVideo = () => (
  <div className="flex flex-row align-middle items-center justify-center h-full w-full">
    <div className="">No Video</div>
  </div>
)

export function PostVideoBlock({ post, embed }: PostVideoBlockProps) {
  const sourceUrl = post?.primaryVideo?.defaultFormat?.sourceUrl
  const contentType = post?.primaryVideo?.defaultFormat?.contentType
  const posterUrl = post?.primaryPoster?.defaultFormat?.sourceUrl

  let src: VideoSource | undefined = sourceUrl
    ? [
        {
          src: sourceUrl,
          type: contentType && mapContentType(contentType),
        },
      ]
    : undefined

  function onReady() {
    console.log('Video Ready')
  }
  return (
    <div className={embed ? 'h-screen h-screen' : 'h-16x9 max-h-screen-90 w-screen bg-black text-gray-100'}>
      {src ? (
        <VideoPlayer
          src={src}
          poster={posterUrl}
          defaultPlaybackRate={1.0}
          playbackRates={[0.4, 0.5, 0.6, 0.7, 0.8, 1.0, 1.2, 1.5, 2.0]}
          onReady={onReady}
        />
      ) : (
        <BlankVideo />
      )}
    </div>
  )
}

PostVideoBlock.fragments = {
  Post: gql`
    fragment PostVideoBlock_Post on Post {
      primaryVideo {
        defaultFormat {
          sourceUrl
          contentType
        }
      }
      primaryPoster {
        defaultFormat {
          sourceUrl
        }
      }
    }
  `,
}

const mapContentType = (contentType: string) =>
  contentType === 'video/x-matroska' ? 'video/mp4' : contentType === 'video/mpeg' ? 'video/mp4' : contentType
