import { Group, User, UserCategoryMember } from '../generated/graphql'

export type NotNullable<T> = Exclude<T, null | undefined>
export type UnpackArray<T> = T extends (infer U)[] ? U : T

export const extractPrincipalId = (
  principal:
    | Pick<Group, '__typename' | 'id'>
    | Pick<User, '__typename' | 'id'>
    | Pick<UserCategoryMember, '__typename' | 'userCategory'>
) => {
  switch (principal.__typename) {
    case 'Group':
    case 'User':
      return principal.id
    case 'UserCategoryMember':
      return principal.userCategory
    default:
      throw new Error(`Unknown principal type ${principal.__typename}`)
  }
}

export const notEmpty = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here")
}

export const containsAny = <T>(checkArray: T[] | undefined, wantedValues: T[]) =>
  Boolean(checkArray ? checkArray.find((ca) => wantedValues.find((wa) => wa === ca)) : false)

export function sortby<T>(key: keyof T) {
  return function (a: T, b: T) {
    return a[key] < b[key] ? -1 : 1
  }
}
