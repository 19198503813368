import React, { useReducer, useState } from 'react'
import { useParams, RouteComponentProps } from 'react-router-dom'
import { PostParams } from './PostRoute'
import { FileUploadButton } from '../../services/fileUpload'
import { doVideoUpload, FileTransferProgressInfo, startPostMediaDownload } from '../../services/sharedServices'
import { useHistory } from 'react-router-dom'
import {
  ErrorBox,
  TextField,
  InfoField,
  PropCard,
  ContentField,
  PropToolbar,
  PropToolbarButton,
  DropConfirmDialog,
  PropBlade,
  Button,
} from '../shared/CommonUi'
import {
  useGetPostEditQuery,
  useUpdatePostMutation,
  GetPostVideoFileFragmentFragment,
  GetPostAuditRecordFragmentFragment,
  GetPostEditFragment,
  UserRoleInput,
  Role,
  useDeleteMediaFileMutation,
  CollectionChrome_UserFragment,
} from '../../generated/graphql'
import { getPostPath } from '../../services/routes'
import { gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { assertUnreachable, containsAny, extractPrincipalId, notEmpty } from '../../services/utilities'
import { UserRoleEditor } from '../shared/UserRoleEditor'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionHeader'
import { RoleSelectBox } from '../shared/RoleSelectBox'
import { EvaluationTypeSelector } from '../evaluation/EvaluationTypeSelector'
import { EvaluationsBlade } from './postEdit/evaluationsBlade'

export const GRAPHQL_RESOURCES = gql`
  fragment getPostVideoFileFragment on VideoFile {
    key
    formats {
      contentType
    }
    defaultFormat {
      contentType
      key
      width
      height
      frameRate
      contentType
    }
    formats {
      contentType
      key
      width
      height
      frameRate
      contentType
      default
    }
    created {
      ...getPostAuditRecordFragment
    }
    updated {
      ...getPostAuditRecordFragment
    }
  }

  fragment getPostAuditRecordFragment on AuditRecord {
    user {
      id
      givenName
      familyName
      userName
    }
    date
  }

  fragment getPostEdit on Post {
    id
    title
    myRoles
    created {
      ...getPostAuditRecordFragment
    }
    updated {
      ...getPostAuditRecordFragment
    }
    primaryVideo {
      ...getPostVideoFileFragment
    }
    primaryPoster {
      findFormat(preferredHeight: 96) {
        sourceUrl
      }
      defaultFormat {
        sourceUrl
        contentType
      }
    }
    collection {
      id
      name
      myRoles
      groups {
        id
        name
        __typename
      }
    }
    roleGrants {
      ...userRoleList
    }
  }

  query getPostEdit($collection_id: String!, $post_id: String!) {
    getCollection(collection_id: $collection_id) {
      post(post_id: $post_id) {
        ...getPostEdit
      }
    }
    me {
      ...CollectionChrome_User
    }
  }

  mutation updatePost($id: String!, $input: PostInput!) {
    updatePost(id: $id, input: $input) {
      ...getPostEdit
    }
  }

  mutation deleteMediaFile($post_id: String!, $mediaFileKey: String!) {
    deleteMediaFile(post_id: $post_id, mediaFileKey: $mediaFileKey) {
      ...getPostEdit
    }
  }
`

interface StateLoading {
  hasData: false
  hasError: false
}

interface StateLoaded {
  hasData: true
  hasError: false
  post: GetPostEditFragment
  me?: CollectionChrome_UserFragment
  cleanPost: GetPostEditFragment
  dirty: Boolean
}

interface StateError {
  hasData: false
  hasError: true
  error: string
}

type State = StateLoading | StateLoaded | StateError

interface ActionLoadData {
  type: 'load_data'
  payload?: { post: GetPostEditFragment; me?: CollectionChrome_UserFragment }
}

interface ActionLoadError {
  type: 'load_error'
  payload: string
}

interface ActionUpdateTitle {
  type: 'update_title'
  payload: string
}

interface ActionUpdateUserRoles {
  type: 'update_userRoles'
  payload: GetPostEditFragment['roleGrants']
}
interface ActionUpdateMedia {
  type: 'update_media'
  payload: {
    primaryVideo: GetPostEditFragment['primaryVideo']
    primaryPoster: GetPostEditFragment['primaryPoster']
  }
}

type Action = ActionLoadData | ActionUpdateTitle | ActionUpdateUserRoles | ActionLoadError | ActionUpdateMedia

const reducer = (state: State, action: Action): State => {
  const addDirty = (state: StateLoaded) => ({
    ...state,
    dirty: JSON.stringify(state.post) !== JSON.stringify(state.cleanPost),
  })
  console.debug(`dispatch ${action.type}`, action.payload)
  switch (action.type) {
    case 'load_data':
      return action.payload
        ? {
            ...state,
            post: action.payload.post,
            me: action.payload.me ?? (state.hasData ? state.me : undefined),
            cleanPost: action.payload.post,
            dirty: false,
            hasData: true,
            hasError: false,
          }
        : { ...state, hasData: false, hasError: false }
    case 'update_title':
      return state.hasData ? addDirty({ ...state, post: { ...state.post, title: action.payload } }) : state // can't update post title if it isn't loaded
    case 'update_userRoles':
      return state.hasData ? addDirty({ ...state, post: { ...state.post, roleGrants: action.payload } }) : state
    case 'load_error':
      return { hasData: false, hasError: true, error: action.payload }
    case 'update_media':
      if (!state.hasData) throw Error('Post must be loaded to update media')
      return {
        ...state,
        post: { ...state.post, primaryVideo: action.payload.primaryVideo, primaryPoster: action.payload.primaryPoster },
      }
  }
  assertUnreachable(action)
  throw new Error(`Unknown state passed to reducer: ${state}`)
}

export function PostEdit(props: RouteComponentProps) {
  const { post_id: post_id_base, collection_id_base } = useParams<PostParams>()
  const post_id = 'po_' + post_id_base
  const collection_id = 'co_' + collection_id_base
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, { hasData: false, hasError: false })
  const { refetch } = useGetPostEditQuery({
    variables: { collection_id, post_id },
    // fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      console.debug(`completed: postEditQuery`)
      console.debug('post get completed', data)
      const inData = data.getCollection?.post
      if (inData) dispatch({ type: 'load_data', payload: { post: inData, me: data.me ?? undefined } })
    },
    onError: (error) => {
      console.debug(`referch error`, error)
      dispatch({ type: 'load_error', payload: error.message })
    },
  })
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string | undefined>()
  const [updatePostMutation] = useUpdatePostMutation({
    onCompleted: (data) => {
      if (data.updatePost) {
        dispatch({ type: 'load_data', payload: { post: data.updatePost } })
      } else {
        dispatch({ type: 'load_error', payload: 'Invalid return value from updatePost mutation' })
      }
    },
  })
  const [deleteMediaFileMutation, deleteMediaFileMutationState] = useDeleteMediaFileMutation({
    onCompleted: (data) => {
      const newPost = data.deleteMediaFile
      if (newPost) {
        dispatch({
          type: 'update_media',
          payload: {
            primaryVideo: newPost.primaryVideo,
            primaryPoster: newPost.primaryPoster,
          },
        })
      }
      setConfirmVideoDelete(false)
    },
  })

  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<FileTransferProgressInfo | undefined>(undefined)
  const [dirtyCancelling, setDirtyCancelling] = useState(false)
  const [confirmVideoDelete, setConfirmVideoDelete] = useState(false)

  const postUploadHandler = async (file: File) => {
    console.log(`Uploading`)
    setUploadErrorMessage(undefined)
    setIsUploading(true)
    try {
      console.debug(`doVideoUpload starting`)
      await doVideoUpload(post_id, file, (progressPercent) => setUploadProgress(progressPercent))
      console.debug(`doVideoUpload complete, success`)
    } catch (e) {
      console.log(`Error uploading`, e)
      if (e instanceof Error) {
        setUploadErrorMessage(e.message)
      }
    }
    // don't clear upload status until refetch -- there is a slight delay between upload and the new post data; if we set uploading done before
    // the new data loads, it flashes back the upload select UI momentarily
    console.debug(`refetch`)
    const refetchResult = await refetch()
    console.debug(`refetch result`, refetchResult)
    const updatedPost = refetchResult.data.getCollection?.post
    if (updatedPost) {
      dispatch({
        type: 'update_media',
        payload: { primaryPoster: updatedPost.primaryPoster, primaryVideo: updatedPost.primaryVideo },
      })
    }
    console.debug(`cleaning up state after upload`)
    setIsUploading(false)
    setUploadProgress(undefined)
  }

  const handleDone = () => {
    if (state.hasData) {
      updatePostMutation({
        variables: {
          id: state.post.id,
          input: {
            title: state.post.title,
            roleGrants: state.post.roleGrants?.filter(notEmpty).map<UserRoleInput>((ur) => ({
              principal_id: extractPrincipalId(ur?.principal),
              role: ur?.role,
            })),
          },
        },
      })
    }
  }

  const handleDownload = (formatKey?: string) => {
    if (state.hasData) {
      const post = state.post
      const media = post.primaryVideo
      const format = formatKey ? media?.formats.find((f) => f.key === formatKey) : media?.defaultFormat

      if (media && format) {
        startPostMediaDownload(post.collection.id, post.id, media.key, format.key)
      }
    }
  }

  const handleDeletePrimaryVideo = () => {
    const mediaFileKey = state.hasData && state.post.primaryVideo?.key
    if (mediaFileKey)
      deleteMediaFileMutation({
        variables: {
          post_id,
          mediaFileKey,
        },
      })
  }

  const handleCancel = (force: boolean) => {
    if (state.hasData && state.dirty && !force) {
      setDirtyCancelling(true)
    } else {
      history.push(getPostPath(collection_id)(post_id))
    }
  }

  const AuditRecordField = ({ label, record }: { label: string; record?: GetPostAuditRecordFragmentFragment | null }) => {
    const userLabel = !record?.user
      ? 'unknown'
      : record.user?.familyName
      ? `${record.user.givenName} ${record.user.familyName}`
      : record.user.userName

    //@ts-ignore
    const dateLabel = !record?.date ? 'unknown' : DateTime.fromISO(record.date).toLocaleString(DateTime.DATETIME_SHORT)
    return record ? <InfoField label={label} value={`${userLabel} at ${dateLabel}`} /> : <div />
  }

  const MediaInfoBlock = ({
    media,
    posterMediaFormat,
  }: {
    media?: GetPostVideoFileFragmentFragment
    posterMediaFormat?: { sourceUrl: string }
  }) => {
    const mf = media?.defaultFormat
    return (
      <>
        <ContentField>
          <div className="w-full bg-black flex h-32">
            {posterMediaFormat?.sourceUrl ? (
              <img className="h-32" src={posterMediaFormat?.sourceUrl} alt="video poster" />
            ) : (
              <div className="w-full h-full text-white text-center">Video Processing</div>
            )}
          </div>
        </ContentField>
        {mf?.width ? (
          <InfoField
            label="dimensions"
            value={((mf.width && mf.height && `${mf.width}x${mf.height}`) || '') + (mf.frameRate ? `${mf.frameRate} fps` : '')}
          />
        ) : (
          <div />
        )}
        <InfoField label="container format" value={media?.defaultFormat?.contentType || ''} />
        <AuditRecordField label="created" record={media?.created} />
        <AuditRecordField label="updated" record={media?.updated} />
      </>
    )
  }

  if (!state.hasData) {
    return state.hasError ? <div>Error: {JSON.stringify(state.error, null, ' ')} </div> : <div>Loading...</div>
  }

  const collection = state.post.collection
  const me = state.me
  const isAdmin = Boolean(state.post.collection.myRoles.find((mr) => mr === Role.Admin))

  return (
    <CollectionChrome collection={collection} navPage={NavPage.Entries} me={me ?? null}>
      <>
        <form>
          <PropBlade>
            <PropToolbar>
              <PropToolbarButton onClick={handleDone} className={state.dirty ? '' : 'hidden'}>
                Save Changes
              </PropToolbarButton>
              <PropToolbarButton onClick={() => handleCancel(false)} disabled={isUploading}>
                Close
              </PropToolbarButton>
              <DropConfirmDialog
                visible={dirtyCancelling}
                onCancel={() => setDirtyCancelling(false)}
                onConfirm={() => handleCancel(true)}
                title="Unsaved changes"
                message="Changes will be lost without saving"
              />
            </PropToolbar>
            <PropCard title="Video Information">
              <>
                <div></div>
                <div>
                  <InfoField label="id" value={state.post.id} />

                  {containsAny(state.post.myRoles, [Role.Admin, Role.Editor]) ? (
                    <TextField
                      id="titleField"
                      label="Title"
                      value={state.post.title}
                      onChange={(e) => dispatch({ type: 'update_title', payload: e.currentTarget.value })}
                    />
                  ) : (
                    <InfoField label="Title" value={state.post.title} />
                  )}
                  <AuditRecordField label="created" record={state.post.created} />
                  <AuditRecordField label="updated" record={state.post.updated} />
                </div>
              </>
            </PropCard>
            <DropConfirmDialog
              visible={confirmVideoDelete}
              message="This will delete this video including. Continue?"
              title="Delete Video"
              confirmButtonText="Delete"
              cancelButtonText="Cancel"
              onCancel={() => setConfirmVideoDelete(false)}
              onConfirm={() => handleDeletePrimaryVideo()}
            ></DropConfirmDialog>
            <PropCard
              title="Primary Video"
              titleContent={
                <>
                  <PropToolbarButton
                    visible={state.post.primaryVideo?.defaultFormat !== null && isAdmin}
                    onClick={() => handleDownload()}
                  >
                    {' '}
                    Download
                  </PropToolbarButton>
                  <PropToolbarButton
                    visible={state.post.primaryVideo !== null}
                    disabled={deleteMediaFileMutationState.loading}
                    onClick={() => setConfirmVideoDelete(true)}
                  >
                    {' '}
                    Delete
                  </PropToolbarButton>
                </>
              }
            >
              {state.post.primaryVideo ? (
                <MediaInfoBlock media={state.post.primaryVideo} posterMediaFormat={state.post.primaryPoster?.findFormat} />
              ) : (
                <FileUploadButton uploadHandler={postUploadHandler} uploading={isUploading} uploadProgress={uploadProgress} />
              )}
              {uploadErrorMessage && <ErrorBox message={uploadErrorMessage} />}
              <div>Other Formats</div>
              <table className="table-auto max-w-64 p-2">
                <tbody>
                  {state.post.primaryVideo?.formats
                    .filter((f) => !f.default)
                    .map((f) => (
                      <tr>
                        <td className="px-8">{f.key}</td>
                        <td className="px-8">{f.contentType}</td>
                        <td className="px-8">
                          {f.width && f.height ? (
                            <span>
                              {f.width} x {f.height}{' '}
                            </span>
                          ) : null}
                        </td>
                        <td className="px-8">{f.frameRate}</td>
                        <td className="px-8">
                          <Button onClick={() => handleDownload(f.key)}>Download</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </PropCard>

            {containsAny(state.post.myRoles, [Role.Admin]) && (
              <PropCard title="Roles">
                {state.post.roleGrants && (
                  <UserRoleEditor
                    value={state.post.roleGrants ? state.post.roleGrants.filter(notEmpty) : []}
                    onChange={(ev) => dispatch({ type: 'update_userRoles', payload: ev })}
                    groupList={state.post.collection.groups?.filter(notEmpty)}
                  />
                )}
              </PropCard>
            )}
            {isAdmin && <EvaluationsBlade />}
          </PropBlade>
        </form>
      </>
    </CollectionChrome>
  )
}
