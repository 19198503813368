import React from 'react'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'
import {
  useGetCollectionQuery,
  useGetCollectionHeaderQuery,
  PostList_PostPageFragment,
  PostList_CollectionFragment,
  PostCard_PostFragment,
} from '../../generated/graphql'
import { getPostPath } from '../../services/routes'
import { useQueryState } from 'use-location-state'
import { gql } from '@apollo/client'
import { Button, CircularProgress, ErrorBox, FilmIcon } from '../shared/CommonUi'
import { NavPage } from '../shared/CollectionNav'
import { CollectionChrome } from '../shared/CollectionChrome'

export const GET_COLLECTION_QUERY = gql`
  query getCollection($collection_id: String!, $limit: Int, $after: String, $before: String, $searchString: String) {
    getCollection(collection_id: $collection_id) {
      id
      name
      myRoles

      ${CollectionChrome.fragments.collection}
      ...PostList_Collection
      posts(limit: $limit, after: $after, before: $before, searchString: $searchString) {
        ...PostList_PostPage
      } 
    }
  }
`
export const GET_COLLECTION_HEADER_QUERY = gql`
  query getCollectionHeader($collection_id: String!) {
    getCollection(collection_id: $collection_id) {
      id
      name
      myRoles
      ${CollectionChrome.fragments.collection}
    }

    me {
      ...CollectionChrome_User
      ${CollectionChrome.fragments.me}
    }
  }
`

export interface CollectionViewParams {
  collection_id_base: string
}

function lastItem<T>(inArray?: T[]): T | undefined {
  if (!inArray || !inArray.length) return undefined
  return inArray[inArray.length - 1]
}

export interface PostCardProps {
  post: PostCard_PostFragment
  getPostPathC: (postID: string) => string
}

const PostCard = ({ post, getPostPathC }: PostCardProps) => {
  const hasVideo = post.primaryVideo?.defaultFormat.key
  return (
    <RouterLink to={getPostPathC(post.id)} className="w-full lg:w-1/2 xl:w-1/3 h-16 md:h-24 p-1">
      <div className="w-full h-full space-x-1 md:space-x-2 flex flex-row  bg-white shadow-md ">
        <div className={'h-full w-32 md:w-48 flex-shrink-0 ' + (hasVideo ? 'bg-gray-300' : 'bg-gray-700')}>
          {post.primaryPoster?.findFormat?.sourceUrl ? (
            <img
              className="h-full w-full object-cover"
              src={post.primaryPoster?.findFormat?.sourceUrl}
              title={post.title}
              alt="thumbnail"
            />
          ) : hasVideo ? (
            <div className="border-2 border-gray-400 h-full w-full">
              <FilmIcon />
            </div>
          ) : (
            <div className="border-2 border-gray-800 h-full w-full"></div>
          )}
        </div>
        <div className="text-md md:text-lg truncate overflow-ellipsis overflow-hidden flex-grow-0">{post.title}</div>
      </div>
    </RouterLink>
  )
}

PostCard.fragments = {
  post: gql`
    fragment PostCard_Post on Post {
      id
      title
      primaryPoster {
        findFormat(preferredHeight: 96) {
          sourceUrl
        }
      }
      primaryVideo {
        key
        defaultFormat {
          key
        }
      }
    }
  `,
}

export interface PostListProps {
  posts: PostList_PostPageFragment
  collection: PostList_CollectionFragment
  pagePosts: (step: 'f' | 'b') => void
}

export const PostList = ({ collection, posts, pagePosts }: PostListProps) => {
  const getPostPathC = getPostPath(collection.id)
  return (
    <>
      {posts.pageInfo.hasNextPage || posts.pageInfo.hasPreviousPage ? (
        <div className="flex space-x-4 p-4">
          <Button type="button" onClick={() => pagePosts('b')} disabled={!posts.pageInfo.hasPreviousPage}>
            Prev
          </Button>
          <Button type="button" onClick={() => pagePosts('f')} disabled={!posts.pageInfo.hasNextPage}>
            Next
          </Button>
        </div>
      ) : (
        <div />
      )}
      <div className="flex flex-wrap">
        {posts.edges?.map((n) => n && <PostCard post={n.node} key={n.node.id} getPostPathC={getPostPathC} />)}
      </div>
    </>
  )
}

PostList.fragments = {
  postPage: gql`
    fragment PostList_PostPage on PostPage {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          ...PostCard_Post
        }
      }
    }
  `,
  collection: gql`
    fragment PostList_Collection on Collection {
      id
    }
  `,
}

export const CollectionView = () => {
  const history = useHistory()
  const { collection_id_base } = useParams<CollectionViewParams>()
  const [pageAfter, setPageAfter] = useQueryState('pa', '')
  const [pageBefore, setPageBefore] = useQueryState('pb', '')
  const [searchString, setSearchString] = useQueryState('s', '')
  const pageSize = 24
  const collection_id = `co_${collection_id_base}`
  const { data, loading, error } = useGetCollectionQuery({
    variables: {
      collection_id,
      limit: pageSize,
      after: pageAfter === '' ? undefined : pageAfter,
      before: pageBefore === '' ? undefined : pageBefore,
      searchString,
    },
    fetchPolicy: 'cache-and-network',
  })
  const {
    data: headerData,
    loading: headerLoading,
    error: headerError,
  } = useGetCollectionHeaderQuery({
    variables: { collection_id },
  })
  const onSearch = (searchString: string) => {
    setPageAfter(null)
    setPageBefore(null)
    setSearchString(searchString)
  }

  const edges = data?.getCollection?.posts?.edges
  const pagePosts = (direction: 'f' | 'b') => {
    const args: { limit: number; after: string | null; before: string | null } = { limit: pageSize, after: null, before: null }
    if (direction === 'f') {
      const cursor = edges && lastItem(edges)?.cursor
      console.log(`new cursor: ${cursor}, edges`, edges)
      args.after = cursor || null
      setPageAfter(cursor || '')
      setPageBefore('')
    } else {
      const cursor = edges && edges[0]?.cursor
      args.before = cursor || null
      setPageBefore(cursor || '')
      setPageAfter('')
    }
    // refetch(args)
  }

  if (error) {
    console.log(error.message)
    if (error.message.indexOf('Permission denied') > -1) {
      console.log('permd')
      history.push('/')
    } else {
      return <ErrorBox message={'PERMD: ' + error.message} />
    }
  }
  if (!headerData?.getCollection) {
    if (loading) {
      return <div>Loading...</div>
    }
    return <ErrorBox message={`Collection ${collection_id} not found`} />
  }

  return (
    <CollectionChrome
      collection={headerData.getCollection}
      navPage={NavPage.Entries}
      me={headerData.me ?? null}
      onSearch={onSearch}
      searchString={searchString}
    >
      {data?.getCollection && data.getCollection.posts ? (
        <PostList collection={data.getCollection} posts={data.getCollection.posts} pagePosts={pagePosts} />
      ) : (
        <CircularProgress />
      )}
    </CollectionChrome>
  )
}
