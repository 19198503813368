import { assign, createMachine, Interpreter, State } from 'xstate'

export interface ContextType {
  videoInfo: {
    duration: number
  }
  currentTime: number
}
type setCurrentTimeEvent = { type: 'SET_CURRENT_TIME'; currentTime: number }
export const setCurrentTime = assign<ContextType, setCurrentTimeEvent>({ currentTime: (context, event) => event.currentTime })

type EventTypes = setCurrentTimeEvent

type UserTypestate = { value: 'unknown'; context: ContextType }

export const playerMachine = () =>
  createMachine<ContextType, EventTypes, UserTypestate>(
    {
      id: 'videoPlayer',
      initial: 'unknown',
      context: {
        videoInfo: {
          duration: 0,
        },
        currentTime: 0,
      },
      states: {
        unknown: {},
      },
      on: {
        SET_CURRENT_TIME: {
          actions: ['setCurrentTime'],
        },
      },
    },
    {
      actions: {
        setCurrentTime,
      },
    }
  )

export type PlayerInterpreter = Interpreter<ContextType, any, EventTypes, UserTypestate>
export type StateType = State<ContextType, EventTypes, any, UserTypestate>

// export const usePlayerMachine = () => useMachine(machine)

// const [state, send] = usePlayerMachine()

// export type StateType = ReturnType<typeof usePlayerMachine>[0]
// export type SendType = ReturnType<typeof usePlayerMachine>[1]
