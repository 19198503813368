import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { CollectionHeader } from './CollectionHeader'
import { CollectionNav, NavPage } from './CollectionNav'
import { CollectionChrome_CollectionFragment, CollectionChrome_UserFragment } from '../../generated/graphql'
import { RootHeader } from './RootHeader'

export interface Props {
  children?: React.ReactNode
  me: CollectionChrome_UserFragment | null
}

export const RootChrome = (props: Props) => {
  const [mobileNavVisible, setMobileNavVisible] = useState(false)
  const toggleMobileTabVisible = () => setMobileNavVisible(!mobileNavVisible)
  return (
    <div
      className="flex h-screen bg-gray-50 dark:bg-gray-900"
      // :class="{ 'overflow-hidden': isSideMenuOpen }"
    >
      <div className="flex flex-col flex-1 w-full">
        <RootHeader me={props.me} />
        {props.children}
      </div>
    </div>
  )
}

RootChrome.fragments = {
  me: gql`
    fragment RootChrome_User on User {
      ...CollectionHeader_User
    }
  `,
}
