import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

export interface PopupMenuProps {
  children?: React.ReactNode
  closeMenu?: () => void
}

export const PopupMenu = (props: PopupMenuProps) => (
  <ClickAwayListener onClickAway={() => props.closeMenu && props.closeMenu()}>
    <div>
      <ul
        className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
        aria-label="submenu"
      >
        {props.children}
      </ul>
    </div>
  </ClickAwayListener>
)

export interface PopupMenuItemProps {
  label: string
  icon: JSX.Element
  onClick?: () => void
  href?: string
}

export const MenuItem = (props: PopupMenuItemProps) => (
  <li className="flex">
    <a
      className="cursor-pointer inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
      href={props.href}
      onClick={() => props.onClick && props.onClick()}
    >
      <div className="pr-3">{props.icon}</div>
      <span>{props.label}</span>
    </a>
  </li>
)
