import React, { Ref, ButtonHTMLAttributes, MouseEventHandler, HTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { User, Group, UserCategoryMember } from '../../generated/graphql'

export interface ErrorBoxProps {
  message?: string
}
export const ErrorBox = (props: ErrorBoxProps) =>
  props.message ? <div className="rounded-md bg-red-500 text-white font-semibold px-2">{props.message}</div> : <></>

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string
  visible?: boolean
}

export const Button = ({ to, children, className, ...rest }: ButtonProps) => {
  const clLink = 'flex items-center  '
  const cl =
    'px-2 py-1 bg-gray-200 rounded-sm shadow-sm cursor-pointer text-md ml-2 space-x-2 disabled:opacity-50' +
    ' ' +
    (className || '')
  return to ? (
    <Link className={cl} to={to}>
      {children}
    </Link>
  ) : (
    <button className={cl} type="button" {...rest}>
      {children}
    </button>
  )
}

export interface ProgressProps extends React.BaseHTMLAttributes<SVGElement> {}

export const CircularProgress = ({ className, ...rest }: ProgressProps) => (
  <svg className={'w-6 h-6 animate-spin' + (className || '')} fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

// Property list compononents

export const PropBlade = (props: { children: React.ReactNode[] }) => (
  <div className="bg-gray-200 overflow-y-auto flex flex-col">{props.children}</div>
)

export const PropToolbar = ({ children }: { children: React.ReactNode }) => {
  return (
    <div>
      <div className="w-full bg-gray-400 px-1 text-gray-100 flex py-1 ">{children}</div>
    </div>
  )
}

export const PropToolbarButton = ({ to, children, className, visible, ...rest }: ButtonProps) => {
  const cl =
    'items-center px-1 py-0 bg-blue-500 rounded-sm shadow cursor-pointer text-sm ml-1 space-x-2 disabled:opacity-50' +
    ' ' +
    (className || '')
  return visible === false ? null : to ? (
    <a className={cl} href={to}>
      {children}
    </a>
  ) : (
    <button className={cl} type="button" {...rest}>
      {children}
    </button>
  )
}

export const PropCard = (props: { title: string; children: React.ReactNode; titleContent?: React.ReactNode }) => (
  <div>
    <div className="w-full bg-gray-400 px-1 text-gray-100">
      <span className="mr-2">{props.title}</span>
      {props.titleContent}
    </div>
    <div>{props.children}</div>
  </div>
)

export const FieldLabel = ({ title, htmlFor, children }: React.LabelHTMLAttributes<HTMLLabelElement>) => (
  <label htmlFor={htmlFor} className="block text-sm font-semibold text-gray-600 uppercase">
    {children}
  </label>
)

export interface TextFieldProps extends React.BaseHTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  value?: string
  inputRef?: Ref<HTMLInputElement>
}

export const TextBox = ({ inputRef, className, ...rest }: TextFieldProps) => (
  <input
    type="text"
    ref={inputRef}
    className={
      FieldContentsClassBase +
      ' border-gray-400 focus:border-gray-600 border-2 bg-transparent appearance-none focus:outline-none focus:bg-white focus:shadow-inner ' +
      (className || '')
    }
    {...rest}
  />
)

export interface CheckboxFieldProps extends React.BaseHTMLAttributes<HTMLInputElement> {
  label?: string
  name?: string
  checked?: boolean
  inputRef?: Ref<HTMLInputElement>
}

export const CheckBox = ({ inputRef, className, checked, onChange, ...rest }: CheckboxFieldProps) => (
  <input
    type="checkbox"
    ref={inputRef}
    defaultChecked={checked}
    onChange={onChange}
    className={
      // FieldContentsClassBase +
      ' border-gray-400 w-4 h-4 focus:border-gray-600 focus:shadow-inner ' + (className || '')
    }
    {...rest}
  />
)

interface TextBoxWithButtonProps extends TextFieldProps {
  icon?: React.ReactNode
  onButtonClick: MouseEventHandler<HTMLButtonElement>
}
export const TextBoxWithButton = ({ icon, onButtonClick, ...restProps }: TextBoxWithButtonProps) => (
  <div className="flex flex-row space-x-0">
    <TextBox className="border-0" {...restProps} />
    <Button className="border-0" onClick={onButtonClick}>
      {icon ?? <SearchIcon />}
    </Button>
  </div>
)

const FieldContentsClassBase = 'block w-full p-1 text-gray-800 appearance-none text-md font-semibold'

/** field block with editing text box */
export const TextField = ({ id, name, value, onChange, label, className, inputRef, ...rest }: TextFieldProps) => (
  <div className="p-2">
    <FieldLabel htmlFor={id}>{label}</FieldLabel>
    <TextBox id={id} name={name} value={value} onChange={onChange} inputRef={inputRef} className={className} {...rest} />
  </div>
)

/** A field block that displays single-line text */
export const InfoField = ({ id, value, label, className, ...rest }: TextFieldProps) => (
  <div className="p-2">
    <FieldLabel htmlFor={id}>{label}</FieldLabel>
    <div id={id} className={FieldContentsClassBase + ' ' + ' ' + (className || '')} {...rest}>
      {value}{' '}
    </div>
  </div>
)

export const CheckboxField = ({ id, name, checked, onChange, label, className, inputRef, ...rest }: CheckboxFieldProps) => (
  <div className="p-2">
    <FieldLabel htmlFor={id}>{label}</FieldLabel>
    <CheckBox id={id} name={name} checked={checked} onChange={onChange} inputRef={inputRef} className={className} {...rest} />
  </div>
)

/** A Field block with no label */
export const ContentField = ({ id, children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className="p-0">{children}</div>
)
//ICONS

interface IconProps extends React.BaseHTMLAttributes<SVGElement> {}

const IconBase = ({ children, className, ...rest }: IconProps) => (
  <svg
    className={(className || '') + 'w-6 h-6 '}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
  </svg>
)

export const CloudUploadIcon = ({ className, ...rest }: IconProps) => (
  <svg
    className={'w-6 h-6' + (className || '')}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
    ></path>
  </svg>
)

export const AddIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
  </IconBase>
)

export const HomeIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
    ></path>
  </IconBase>
)

export const CameraIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
    ></path>
  </IconBase>
)

export const PersonIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
    ></path>
  </IconBase>
)

export const ExclamationIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
    ></path>
  </IconBase>
)

export const EditIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
    ></path>
  </IconBase>
)

export const SearchIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
  </IconBase>
)

export const XIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
  </IconBase>
)

export const GroupIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    ></path>
  </IconBase>
)

export const ChevronLeft = (p: IconProps) => (
  <IconBase {...p}>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
  </IconBase>
)

export const FilmIcon = (p: IconProps) => (
  <IconBase {...p}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
    ></path>
  </IconBase>
)

export interface ModalDialogProps {
  visible?: boolean
  children?: React.ReactNode
}
export const ModalDialog = ({ visible, children }: ModalDialogProps) => (
  <div className={`fixed z-10 inset-0 overflow-y-auto ${visible ? '' : 'hidden'}`}>
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <div
        className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {children}
      </div>
    </div>
  </div>
)

export interface DialogButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  format?: 'default' | 'warning'
}
export const DialogButton = ({ format, children, className, ...restProps }: DialogButtonProps) => {
  format = format ?? 'default'
  const formatClassName =
    format === 'warning'
      ? 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 '
      : 'bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500'
  return (
    <button
      type="button"
      className={` mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${formatClassName} ${className}`}
      {...restProps}
    >
      {children}
    </button>
  )
}

export interface DropConfigmDialogProps {
  message: string
  title: string
  visible?: boolean
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  onCancel: () => void
}

/** A full-screen modal with continue or cancel options */
export const DropConfirmDialog = (props: DropConfigmDialogProps) => {
  const visible = props.visible ?? false
  const confirmButtonText = props.confirmButtonText ?? 'Confirm'
  const cancelButtonText = props.cancelButtonText ?? 'Cancel'
  return (
    <ModalDialog visible={visible}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-600 sm:mx-0 sm:h-10 sm:w-10 white">
            <ExclamationIcon />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              {props.title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{props.message}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <DialogButton onClick={props.onConfirm} format="warning">
          {confirmButtonText}
        </DialogButton>
        <DialogButton onClick={props.onCancel}>{cancelButtonText}</DialogButton>
      </div>
    </ModalDialog>
  )
}

export interface PrincipalLabelProps extends HTMLAttributes<HTMLDivElement> {
  principal?:
    | Pick<User, '__typename' | 'id' | 'givenName' | 'familyName' | 'displayName' | 'userName'>
    | Pick<Group, '__typename' | 'id' | 'name'>
    | Pick<UserCategoryMember, '__typename' | 'userCategory'>
}
export const PrincipalLabel = ({ principal, className, ...rest }: PrincipalLabelProps) => {
  if (!principal) return <div />
  switch (principal.__typename) {
    case 'Group':
      return (
        <div className={`flex space-x-1 ${className}`} {...rest}>
          <GroupIcon className="w-4 h-4" />
          <span>{principal.name || principal.id}</span>
        </div>
      )
    case 'User':
      return (
        <div className="flex space-x-1">
          <PersonIcon className="w-4 h-4" />
          <span>
            {principal.displayName ??
              ((principal.givenName || principal.familyName) && `${principal.givenName} ${principal.familyName}`) ??
              principal.userName}
          </span>
        </div>
      )
    case 'UserCategoryMember':
      return <div>Category: {principal.userCategory}</div>
    default:
      return <div />
  }
}
export interface TdlProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  // children: React.ReactNode
  to?: string
}

// TD with Link
export const Tdl = ({ to, children, ...rest }: TdlProps) => {
  if (!to) return <td {...rest}>{children}</td>
  return (
    <td {...rest}>
      <Link to={to}>{children}</Link>
    </td>
  )
}
