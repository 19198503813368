import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CollectionNav_CollectionFragment, ProfileMenu_UserFragment, Role, useCreatePostMutation } from '../../generated/graphql'
import {
  getCollectionPath,
  getCollectionPathAssignments,
  getCollectionPathEdit,
  getCollectionPathPlaylists,
  getPostPathEdit,
} from '../../services/routes'
import { containsAny } from '../../services/utilities'
import { HomeIcon } from './CommonUi'
import { MenuItemDef, NavHeader } from './header/NavHeader'

export interface Props {
  collection: CollectionNav_CollectionFragment
  onNav?: (page: NavPage) => void
  toggleMobileNavVisible: () => void
  me: ProfileMenu_UserFragment | null
  navPage: NavPage
  searchString?: string
  onSearch?: (searchString: string) => void
}

export enum NavPage {
  Entries = 'Entries',
  Playlists = 'Playlists',
  Assignments = 'Assignments',
  Settings = 'Settings',
  CreateEntry = 'CreateEntry',
}

export const CollectionHeader = (props: Props) => {
  const menuItems: MenuItemDef[] = [
    {
      name: NavPage.Entries,
      icon: <HomeIcon />,
      href: getCollectionPath(props.collection.id),
    },
    {
      name: NavPage.Playlists,
      icon: <HomeIcon />,
      href: getCollectionPathPlaylists(props.collection.id),
    },
    // {
    //   name: NavPage.Assignments,
    //   icon: <HomeIcon />,
    //   href: getCollectionPathAssignments(props.collection.id),
    // },
    {
      name: NavPage.Settings,
      icon: <HomeIcon />,
      requiredRoles: [Role.Admin],
      href: getCollectionPathEdit(props.collection.id),
    },
  ]
  const menuItemsMapped = menuItems
    // set active
    .map((mi) => ({ ...mi, active: mi.name === props.navPage }))
    // security trim
    .filter((mi) => !mi.requiredRoles || containsAny(props.collection.myRoles, mi.requiredRoles))

  const canCreatePost = props.collection.myRoles.find((r) => r === Role.Admin)
  const history = useHistory()
  const [createPost] = useCreatePostMutation({
    onCompleted: (data) => {
      const id = data.createPost?.id
      props.collection?.id && id && history.push(getPostPathEdit(props.collection?.id)(id))
    },
  })
  const doUpload = () => {
    if (props.collection?.id) createPost({ variables: { collection_id: props.collection.id } })
  }

  return (
    <NavHeader
      title={props.collection.name}
      menuItems={menuItemsMapped}
      me={props.me}
      titleHref={getCollectionPath(props.collection.id)}
      onSearch={props.onSearch}
      searchString={props.searchString}
      onCreatePost={canCreatePost ? doUpload : undefined}
    />
  )
}

CollectionHeader.fragments = {
  user: gql`
    fragment CollectionHeader_User on User {
      ...ProfileMenu_User
    }
    fragment CollectionHeader_Collection on Collection {
      myRoles
    }
  `,
}
