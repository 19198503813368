import React, { useReducer, useState } from 'react'
import { useParams, RouteComponentProps } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

type ValueOf<T> = T[keyof T]
const NullKey = 'NULL'

export const EvaluationTypeSelector = () => {
  return <span>Type Selector</span>
  // <select onChange={handeleOnChange} value={value ?? NullKey} className={className}>
  //   <option key={NullKey} value={''}>
  //     Select
  //   </option>
  //   {Object.entries(Role)?.map((g) => (
  //     <option key={g[1]} value={g[1]}>
  //       {g[1]}
  //     </option>
  //   ))}
  // </select>
}
