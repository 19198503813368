import React, { useState } from 'react'
import { TextBox, TextBoxWithButton, SearchIcon, CircularProgress, ErrorBox, PrincipalLabel, Button, XIcon } from './CommonUi'
import {
  User,
  Group,
  useUserSearchBox_FindUserQuery,
  useUserSearchBox_FindUserLazyQuery,
  UserSearchBox_UserFragment,
  UserSearchBox_CreatePlaceholderUserDocument,
  useUserSearchBox_CreatePlaceholderUserMutation,
} from '../../generated/graphql'
import { gql } from '@apollo/client'

const GRAPHQL_CONTENT = gql`
  fragment userSearchBox_user on User {
    id
    givenName
    familyName
    displayName
    userName
  }
  query userSearchBox_findUser($collection_id: String!, $userName: String!) {
    getCollection(collection_id: $collection_id) {
      findUser(userName: $userName) {
        ...userSearchBox_user
      }
    }
  }
  mutation userSearchBox_createPlaceholderUser($collection_id: String!, $userName: String!) {
    createPlaceholderUser(collection_id: $collection_id, userName: $userName) {
      ...userSearchBox_user
    }
  }
`

type UserPartial = UserSearchBox_UserFragment

export interface UserSearchBoxProps {
  onValueChanged: (selection: UserPartial | null) => void
  value: UserPartial | null
  collectionID: string
}
export const UserSearchBox = ({ value, onValueChanged, collectionID }: UserSearchBoxProps) => {
  const [findUser, { loading, data }] = useUserSearchBox_FindUserLazyQuery({
    onCompleted: (data) => {
      if (!data.getCollection?.findUser?.id) {
        setFindError(`User ${findText} not found`)
        setFindNotFoundEmail(findText)
        if (value) onValueChanged(null)
      } else {
        setFindText('')
        onValueChanged(data.getCollection.findUser)
      }
    },
    fetchPolicy: 'network-only',
  })
  const [createPlaceholderUser] = useUserSearchBox_CreatePlaceholderUserMutation({
    onCompleted: (data) => {
      setFindText('')
      setFindError(undefined)
      setFindNotFoundEmail(undefined)
      onValueChanged(data.createPlaceholderUser)
    },
    onError: (err) => {
      setFindError(`Could not create placholder user; Error: ${err.message}`)
    },
  })
  const [findText, setFindText] = useState('')
  const [findError, setFindError] = useState<string | undefined>()
  const [findNotFoundEmail, setFindNotFoundEmail] = useState<string | undefined>()
  const handleDoSearch = () => {
    setFindError(undefined)
    setFindNotFoundEmail(undefined)
    findUser({ variables: { collection_id: collectionID, userName: findText } })
  }
  const handleCreatePlacholderUser = () => {
    if (findNotFoundEmail) {
      createPlaceholderUser({ variables: { collection_id: collectionID, userName: findNotFoundEmail } })
    }
  }

  return value !== null ? (
    <div className="flex flex-row w-full space-x-2">
      <PrincipalLabel principal={value} className="flex-auto" />
      <Button className="w-6 h-6 flex-grow=0" onClick={() => onValueChanged(null)}>
        <XIcon className="w-6 h-6" />
      </Button>
    </div>
  ) : (
    <>
      <TextBoxWithButton
        value={findText}
        placeholder="search by email address"
        onChange={(ev) => setFindText(ev.currentTarget.value)}
        onKeyPress={(ev) => {
          if (ev.charCode === 13) {
            ev.preventDefault()
            handleDoSearch()
          }
        }}
        icon={loading ? <CircularProgress /> : <SearchIcon />}
        onButtonClick={handleDoSearch}
      />
      <ErrorBox message={findError} />
      {findNotFoundEmail ? <Button onClick={handleCreatePlacholderUser}>Create User</Button> : null}
    </>
  )
}
