import { gql } from '@apollo/client'
import { PropertiesPlugin } from '@microsoft/applicationinsights-web'
import React, { useState } from 'react'
import { ProfileMenu_UserFragment } from '../../../generated/graphql'
import { useAuthentication } from '../../../services/authProvider'
import { Button, HomeIcon, PersonIcon } from '../CommonUi'
import { PopupMenu, MenuItem } from '../ui/PopupMenu'

export interface Props {
  me: ProfileMenu_UserFragment | null
}

export const ProfileMenu = (props: Props) => {
  const { interactiveLogin, logout, mainAccount } = useAuthentication('nav')
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const closeProfileMenu = () => setProfileMenuOpen(false)
  const toggleProfileMenu = () => setProfileMenuOpen(!profileMenuOpen)

  const handleLogoutClick = async () => {
    await logout()
    setProfileMenuOpen(false)
  }

  async function handleLoginClick() {
    interactiveLogin()
  }

  return (
    <div className="px-3 h-auto">
      {props.me !== null ? (
        <>
          <button
            className="align-middle font-semibold text-gray-800 flex flex-row"
            onClick={() => toggleProfileMenu()}
            aria-label="Account"
            aria-haspopup="true"
          >
            <div className="mr-1 hidden lg:inline-flex">{props.me?.userName}</div>
            <PersonIcon className="object-cover w-6 h-6 rounded-full" />
          </button>
          {profileMenuOpen ? (
            <PopupMenu closeMenu={closeProfileMenu}>
              <MenuItem label="Logout" icon={<HomeIcon />} onClick={handleLogoutClick} />
            </PopupMenu>
          ) : null}
        </>
      ) : (
        <Button onClick={handleLoginClick}>Login</Button>
      )}
    </div>
  )
}

ProfileMenu.fragments = {
  user: gql`
    fragment ProfileMenu_User on User {
      id
      userName
      displayName
      globalRoles
    }
  `,
}
