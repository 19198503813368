import { gql } from '@apollo/client'
import { PropertiesPlugin } from '@microsoft/applicationinsights-properties-js'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { containsAny } from '../../../services/utilities'
import { Button, HomeIcon } from '../CommonUi'
import { ProfileMenu } from '../header/ProfileMenu'
import { title } from 'process'
import { getCollectionListPath } from '../../../services/routes'
import { ProfileMenu_UserFragment, Role } from '../../../generated/graphql'

export interface Props {
  me: ProfileMenu_UserFragment | null
  menuItems: MenuItemDef[]
  title?: string
  titleHref?: string
  searchString?: string
  onSearch?: (searchString: string) => void
  homeText?: string
  onCreatePost?: () => void
}

export interface MenuItemDef {
  name: string
  icon: JSX.Element
  active?: boolean
  requiredRoles?: Role[]
  onClick?: () => void
  href?: string
}

export const MenuItem = (def: MenuItemDef) => {
  const linkClassBase = 'items-center text-sm'
  const linkClassInactive =
    linkClassBase + ' transition-colors duration-150 text-gray-500 font-medium hover:text-gray-800 dark:hover:text-gray-200'
  const linkClassActive =
    linkClassBase +
    ' text-gray-800 transition-colors font-semibold duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100'
  return (
    <li className="relative px-3 py-3" key={def.name}>
      <a className={def.active ? linkClassActive : linkClassInactive} href={def.href}>
        <span className="">{def.name}</span>
      </a>
      {def.active ? (
        <span className="absolute inset-x-0 bottom-0 h-1 bg-purple-600 rounded-tl-lg rounded-tr-lg" aria-hidden="true"></span>
      ) : null}
    </li>
  )
}

export const NavHeader = (props: Props) => {
  const [searchBoxText, setSearchBoxText] = useState(props.searchString ?? '')
  const handleSearchBoxKey = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      props.onSearch && props.onSearch(searchBoxText)
    }
  }
  return (
    <header className="z-10 shadow-md dark:bg-gray-800 w-full">
      <div className=" flex items-center w-full dark:text-purple-300">
        {/* <!-- Mobile hamburger --> */}
        {/* <button
          className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple"
          // onClick={() => ible()}
          aria-label="Menu"
        >
          <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button> */}
        {/* <!-- Title --> */}

        <div className="flex flex-row">
          <div className="px-4 bg-purple-500 text-white text-xl font-semibold h-14">
            <div className="flex-1 mt-3">
              <Link className="" to={getCollectionListPath()}>
                <div className="flex flex-row">
                  <HomeIcon className="-mb-1.5" />
                  {props.homeText && <div className="px-6">{props.homeText}</div>}
                </div>
              </Link>
            </div>
          </div>
          {props.title && (
            <div className="hidden md:inline px-2 text-gray-800 text-lg font-semibold h-14">
              <div className="flex-1 mt-3 px-2">
                <Link className="" to={props.titleHref ?? ''}>
                  <div className="flex flex-row">
                    <span className="pl-2 whitespace-nowrap">{props.title}</span>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* <!-- Menu items --> */}
        <ul className="pl-2 flex flex-row y-full">{props.menuItems.map(MenuItem)}</ul>
        {/* <!-- Search input --> */}
        <div className="flex justify-center flex-1 lg:mr-32">
          {props.onSearch && (
            <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
              <div className="absolute inset-y-0 flex items-center pl-2">
                <svg className="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                className="w-full h-8 pl-8 pr-2 text-md text-gray-700 placeholder-gray-300 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-purple-300 focus:outline-none focus:shadow-outline-purple form-input"
                type="text"
                placeholder="search entries"
                aria-label="Search"
                value={searchBoxText}
                onChange={(ev) => setSearchBoxText(ev.currentTarget.value)}
                onKeyUp={handleSearchBoxKey}
              />
            </div>
          )}
        </div>
        {/* <!-- Right menu --> */}
        <ul className="flex items-center flex-shrink-0 space-x-6">
          <div className="flex flex-row">
            {props.onCreatePost ? (
              <button
                onClick={props.onCreatePost}
                className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              >
                New Video
                <span className="ml-2" aria-hidden="true">
                  +
                </span>
              </button>
            ) : null}
            <ProfileMenu me={props.me} />
          </div>
        </ul>
      </div>
    </header>
  )
}

NavHeader.fragments = {
  user: gql`
    fragment CollectionHeader_User on User {
      ...ProfileMenu_User
    }
  `,
}
