import React, { useReducer, useState } from 'react'
import { Button, PrincipalLabel, ModalDialog, DialogButton, ErrorBox, TextBox, FieldLabel } from '../shared/CommonUi'
import { gql } from '@apollo/client'
import { GroupEditorFragment, GroupEditUserFragment } from '../../generated/graphql'
import { notEmpty } from '../../services/utilities'
import { UserSearchBox } from './UserSearchBox'

export const GRAPHQL_RESOURCES = gql`
  fragment GroupEditUser on User {
    id
    userName
    givenName
    familyName
    displayName
  }

  fragment groupEditor on Group {
    __typename
    id
    name
    users {
      ...GroupEditUser
    }
  }
`

export interface GroupEditorProps {
  value: GroupEditorFragment[]
  onChange: (newVal: GroupEditorFragment[]) => void
  collectionID: string
}
export const GroupEditor = ({ value, onChange, collectionID }: GroupEditorProps) => {
  const [editingGroupIndex, setEditingGroupIndex] = useState<number | undefined>()
  const [newGroupName, setNewGroupName] = useState('')
  const [addError, setAddError] = useState<string | undefined>(undefined)
  const [addGroupUser, setAddGroupUser] = useState<GroupEditUserFragment | null>(null)
  const editingGroup = editingGroupIndex !== undefined ? value[editingGroupIndex] : undefined
  const handleAdd = () => {
    if (!newGroupName.length) {
      setAddError('Must enter new group name')
      return
    }
    setTimeout(() => onChange([...value, { __typename: 'Group', id: '', name: newGroupName, users: [] }]), 0)
    setAddError(undefined)
    setNewGroupName('')
  }

  const handleRemoveUser = (groupIndex: number, userIndex: number) => {
    const editingGroup = value[groupIndex]
    const newGroup = { ...editingGroup, users: editingGroup.users.filter((u, index) => index !== userIndex) }
    const newGroupList = value.map((g, index) => (index === groupIndex ? newGroup : g))
    setTimeout(() => onChange(newGroupList))
  }

  const handleAddUser = () => {
    console.log('adding usrs', { addGroupUser, editingGroupIndex, editingGroup })
    if (addGroupUser !== null && editingGroupIndex !== undefined && editingGroup) {
      const newGroup = { ...editingGroup, users: [...editingGroup.users, addGroupUser] }
      const newGroupList = value.map((g, index) => (index === editingGroupIndex ? newGroup : g))
      setAddGroupUser(null)
      setTimeout(() => onChange(newGroupList))
    }
  }

  const groupAlreadyContainsThisUser =
    editingGroup && addGroupUser && editingGroup.users.find((user) => user?.id === addGroupUser.id)
  return (
    <>
      <table className="table-fixed w-full max-w-64 p-2">
        <thead>
          <tr>
            <th className="w-1/2"></th>
            <th className="w-1/4"></th>
            <th className="w-1/4"></th>
          </tr>
        </thead>
        <tbody>
          {value.map((gr, index) => (
            <tr key={index}>
              <td>
                <PrincipalLabel principal={gr} />
              </td>
              <td>{`${gr.users.length} ${gr.users.length === 1 ? 'User' : 'Users'}`}</td>
              <td>
                <Button onClick={() => setEditingGroupIndex(index)}>Edit</Button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <TextBox
                placeholder="new group name"
                onChange={(ev) => setNewGroupName(ev.currentTarget.value)}
                value={newGroupName}
              />
              <ErrorBox message={addError} />
            </td>
            <td></td>
            <td>
              <Button onClick={handleAdd} className={`${newGroupName.length > 0 ? '' : 'hidden'}`}>
                Add
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {editingGroupIndex !== undefined && (
        <ModalDialog visible={true}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="pb-2 font-bold">
              {editingGroup?.name} members ({editingGroup?.users?.length})
            </div>
            <table className="table-fixed w-full max-w-64">
              <thead>
                <tr>
                  <th className="w-3/4"></th>
                  <th className="w-1/4"></th>
                </tr>
              </thead>
              <tbody>
                {editingGroup?.users.filter(notEmpty).map((user, index) => (
                  <tr key={index}>
                    <td>
                      <PrincipalLabel principal={user} />
                    </td>
                    <td>
                      <Button onClick={() => handleRemoveUser(editingGroupIndex, index)}>Remove</Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="pt-4">
                    <FieldLabel>Add User</FieldLabel>
                  </td>
                </tr>
                <tr>
                  <td>
                    <UserSearchBox value={addGroupUser} onValueChanged={setAddGroupUser} collectionID={collectionID} />
                    {groupAlreadyContainsThisUser && <ErrorBox message="User is already in group" />}
                  </td>
                  <td>
                    <Button
                      onClick={() => handleAddUser()}
                      className={`${addGroupUser === null || groupAlreadyContainsThisUser ? 'hidden' : ''}`}
                    >
                      Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <DialogButton onClick={() => setEditingGroupIndex(undefined)}>Close</DialogButton>
            </div>
          </div>
        </ModalDialog>
      )}
    </>
  )
}
