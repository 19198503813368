import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { SERVER_GRAPHQL_ENDPOINT } from './services/config'

import { requestAccessToken } from './services/authProvider'

const httpLink = createHttpLink({
  uri: SERVER_GRAPHQL_ENDPOINT,
})

const authLink = setContext(async (req, context) => {
  // get the authentication token from local storage if it exists
  const token = await requestAccessToken()
  console.log(`using bearer token ${token?.substr(0, 10)}...`)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...context.headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-api-key': context.accessTicket,
    },
  }
})

const cache = new InMemoryCache({
  typePolicies: {
    Collection: {
      fields: {
        // posts: {
        //   keyArgs: [],
        //   merge(existing, incoming, { args }) {
        //     const offset = args?.offset
        //     if (!offset) return null
        //     const merged = existing ? existing.slice(0) : []
        //     for (let i = 0; i < incoming.length; ++i) {
        //       merged[offset + i] = incoming[i]
        //     }
        //     console.log('Merged post data')
        //     return merged
        //   },
        // },
      },
    },
  },
})

export const apolloClient = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
})
