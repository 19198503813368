import { gql } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryState } from 'use-location-state'
import {
  AssignmentCard_AssignmentFragment,
  AssignmentTable_AssignmentFragment,
  useAssignmentList_BaseQuery,
} from '../../generated/graphql'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionNav'
import { Button, CircularProgress, Tdl } from '../shared/CommonUi'
import { notEmpty, sortby } from '../../services/utilities'
import { DateTime } from 'luxon'

export interface AssignmentCardProps {
  assignment: AssignmentCard_AssignmentFragment
  collection_id: string
}

const timeUntil = (date: Date) => DateTime.fromJSDate(date).toRelative()
const displayDate = (date: Date) => DateTime.fromJSDate(date).toLocaleString({ timeZoneName: 'short' })

export const AssignmentCard = (props: AssignmentCardProps) => {
  const { assignment, collection_id } = props
  return (
    <tr>
      <td>{assignment.title}</td>
      <td>
        {assignment.status === 'AVAILABLE'
          ? `Available${(assignment.availableEndDate && ' until ' + displayDate(assignment.availableEndDate)) ?? ''}`
          : assignment.status === 'PENDING' && assignment.availableStartDate
          ? `Opens ${displayDate(assignment.availableStartDate)}`
          : assignment.status === 'EXPIRED' && assignment.availableEndDate
          ? `Expired on ${displayDate(assignment.availableEndDate)}`
          : assignment.status}
      </td>
      <td>{assignment.numRounds}</td>
    </tr>
  )
}

AssignmentCard.fragments = {
  playlist: gql`
    fragment AssignmentCard_Assignment on Assignment {
      id
      title
      status
      assignedToMe
      availableStartDate
      availableEndDate
      numRounds
    }
  `,
}

export interface AssignmentTableProps {
  assignments: AssignmentTable_AssignmentFragment[]
  collection_id: string
}

export const AssignmentTable = (props: AssignmentTableProps) => {
  const { assignments, collection_id } = props
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Title
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Rounds
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {assignments.map((e) => (
                  <AssignmentCard key={e.id} assignment={e} collection_id={collection_id} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

AssignmentTable.fragments = {
  playlist: gql`
    fragment AssignmentTable_Assignment on Assignment {
      ...AssignmentCard_Assignment
    }
  `,
}

export interface CollectionViewParams {
  collection_id_base: string
}

function lastItem<T>(inArray?: T[]): T | undefined {
  if (!inArray || !inArray.length) return undefined
  return inArray[inArray.length - 1]
}

export interface AssignmentListPageProps {}

export const AssignmentListPage = (props: AssignmentListPageProps) => {
  const { collection_id_base } = useParams<CollectionViewParams>()
  const collection_id = `co_${collection_id_base}`
  const { data, loading, error } = useAssignmentList_BaseQuery({
    variables: {
      collection_id,
    },
  })
  if (!data?.getCollection) {
    if (loading) return <div>Loading...</div>
    if (error) return <div>Error: ${error.message}</div>
    return <div>Error loading playlist data for collection {collection_id}</div>
  }
  const collection = data.getCollection
  const me = data.me || null

  const assignments = collection.assignments
  return (
    <CollectionChrome collection={collection} me={me} navPage={NavPage.Assignments}>
      {notEmpty(assignments) !== null ? (
        <AssignmentTable assignments={assignments} collection_id={collection.id} />
      ) : loading ? (
        <CircularProgress />
      ) : (
        <div>No playlists found</div>
      )}
    </CollectionChrome>
  )
}

AssignmentListPage.fragments = {
  assignment: gql`
    fragment AssignmentList_Assignment on Assignment {
      ...AssignmentTable_Assignment
    }
  `,
  collection: gql`
    fragment AssignmentList_Collection on Collection {
      ...CollectionChrome_Collection
    }
  `,
}

AssignmentListPage.queries = {
  Base: gql`
    query AssignmentList_Base($collection_id: String!) {
      getCollection(collection_id: $collection_id) {
        ...AssignmentList_Collection
        assignments {
          ...AssignmentList_Assignment
        }
      }
      me {
        ...CollectionChrome_User
      }
    }
  `,
}
