import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CollectionNav_CollectionFragment, Role, useCreatePostMutation } from '../../generated/graphql'
import { getCollectionListPath, getCollectionPath, getCollectionPathEdit, getPostPathEdit } from '../../services/routes'
import { containsAny } from '../../services/utilities'
import { HomeIcon } from './CommonUi'

export interface Props {
  collection: CollectionNav_CollectionFragment
  onNav?: (page: NavPage) => void
  navPage: NavPage
  mobileNavVisible: boolean
  setMobileNavVisible: (val: boolean) => void
}

export enum NavPage {
  Entries = 'Entries',
  Playlists = 'Playlists',
  Assignments = 'Assignments',
  Settings = 'Settings',
  CreateEntry = 'CreateEntry',
}

interface MenuItemDef {
  name: NavPage
  icon: JSX.Element
  active?: boolean
  requiredRoles?: Role[]
  onClick?: () => void
  href?: string
}

const MenuItem = (def: MenuItemDef) => {
  const linkClassBase = 'inline-flex items-center w-full text-sm font-semibold'
  const linkClassInactive = linkClassBase + ' transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200'
  const linkClassActive =
    linkClassBase + ' text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100'
  return (
    <li className="relative px-6 py-3">
      {def.active ? (
        <span className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg" aria-hidden="true"></span>
      ) : null}
      <a className={def.active ? linkClassActive : linkClassInactive} href={def.href}>
        {def.icon}
        <span className="ml-4">{def.name}</span>
      </a>
    </li>
  )
}

export const CollectionNav = (props: Props) => {
  const history = useHistory()
  const [createPost] = useCreatePostMutation({
    onCompleted: (data) => {
      const id = data.createPost?.id
      props.collection?.id && id && history.push(getPostPathEdit(props.collection?.id)(id))
    },
  })
  const doUpload = () => {
    if (props.collection?.id) createPost({ variables: { collection_id: props.collection.id } })
  }
  const menuItems: MenuItemDef[] = [
    {
      name: NavPage.Entries,
      icon: <HomeIcon />,
      href: getCollectionPath(props.collection.id),
    },
    // {
    //   name: NavPage.Playlists,
    //   icon: <HomeIcon />,
    //   href: '',
    // },
    {
      name: NavPage.Assignments,
      icon: <HomeIcon />,
      href: '',
    },
    {
      name: NavPage.Settings,
      icon: <HomeIcon />,
      requiredRoles: [Role.Admin],
      href: getCollectionPathEdit(props.collection.id),
    },
  ]
  const menuItemsMapped = menuItems
    // set active
    .map((mi) => ({ ...mi, active: mi.name === props.navPage }))
    // security trim
    .filter((mi) => !mi.requiredRoles || containsAny(props.collection.myRoles, mi.requiredRoles))
  const baseClass =
    (props.mobileNavVisible ? '' : 'hidden ') +
    'bg-white dark:bg-gray-800 z-20 w-64' +
    ' fixed inset-y-0 flex-shrink-0 mt-16 md:mt-0 overflow-y-auto' +
    ' md:overflow-y-auto  md:block md:flex-shrink-0 md:relative'
  return (
    <aside className={baseClass}>
      <div className="text-gray-500 dark:text-gray-400">
        <div className="px-6 x-fill flex flex-col bg-purple-500 text-white text-xl font-semibold h-14">
          <div className="flex-1 mt-3">
            <Link className="" to={getCollectionListPath()}>
              <div className="flex flex-row">
                <HomeIcon className="-mb-1.5" />
                <span className="pl-2">VideoHub</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="ml-6 py-6 ">
          <a className="text-lg font-bold text-gray-800 dark:text-gray-200" href={getCollectionPath(props.collection.id)}>
            {props.collection.name}
          </a>
        </div>
        <ul className="">{menuItemsMapped.map(MenuItem)}</ul>
        {containsAny(props.collection.myRoles, [Role.Admin, Role.Creator]) && (
          <div className="px-6 my-6">
            <button
              onClick={doUpload}
              className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
            >
              Create Entry
              <span className="ml-2" aria-hidden="true">
                +
              </span>
            </button>
          </div>
        )}
      </div>
    </aside>
  )
}

CollectionNav.fragments = {
  collection: gql`
    fragment CollectionNav_Collection on Collection {
      id
      name
      myRoles
    }
  `,
}
