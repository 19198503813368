import { AAD_B2C_TENANT, AAD_B2C_APPLICATION_ID, AAD_B2C_SIGN_IN_POLICY, API_TOKEN_SCOPE } from './config'
import * as msal from '@azure/msal-browser'
import { useMsal, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType, RedirectRequest } from '@azure/msal-browser'
import { apolloClient } from '../apolloClient'
import { useEffect } from 'react'
import { gql } from '@apollo/client'
import { useGetMeLazyQuery } from '../generated/graphql'

const tenant = AAD_B2C_TENANT
const signInPolicy = AAD_B2C_SIGN_IN_POLICY
const applicationID = AAD_B2C_APPLICATION_ID

if (!tenant || !signInPolicy || !applicationID) throw Error('AAD configuration missing')

const tenantSubdomain = tenant.split('.')[0]
// const instance = `https://${tenantSubdomain}.b2clogin.com/`
// const signInAuthority = `${instance}${tenant}/${signInPolicy}`

const redirectUriObj = new URL(window.location.href)
const redirectUri = `${redirectUriObj.protocol}//${redirectUriObj.hostname}${redirectUriObj.port ? ':' + redirectUriObj.port : ''}`
console.log(`Using redirectUri: ${redirectUri}`)

const msalConfig: msal.Configuration = {
  auth: {
    clientId: applicationID,
    authority: `https://${tenantSubdomain}.b2clogin.com/tfp/${tenantSubdomain}.onmicrosoft.com/${signInPolicy}`,
    redirectUri,
    knownAuthorities: [`${tenantSubdomain}.b2clogin.com`],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {},
}

export const GRAPHQL_CONTENT = gql`
  query getMe {
    me {
      __typename
      id
      userName
    }
  }
`

// console.log(`AAD B2C config`, { tenant, applicationID, tenantSubdomain, instance, signInAuthority, msalConfig })

export const msalInstance = new msal.PublicClientApplication(msalConfig)

if (!API_TOKEN_SCOPE) console.error(`API_TOKEN_SCOPE must be specified`)
export const apiTokenScope = [API_TOKEN_SCOPE || '']
export const otherScopes = ['openid', 'profile']
export const allScopes = [...apiTokenScope, ...otherScopes]
// Use allScopes when doing interactive login so the user concents to everythig we will need in the following
// token requests. If this isn't done, the subsequent silent token requests will fail due to lack of concent.
const redirectRequest: RedirectRequest = {
  scopes: allScopes,
}

export const requestAccessTokenData = async () => {
  const accounts = msalInstance.getAllAccounts()
  const mainAccount = accounts.length > 0 ? accounts[0] : undefined

  console.log(`Requesting access token, using mainAccount ${mainAccount?.username}, accounts: ${accounts?.length}`)
  if (mainAccount) {
    console.debug(`Silently requesting access token`)
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: apiTokenScope,
        account: mainAccount,
      })
      console.debug('requestAccessToken response', response)
      return response
    } catch (error) {
      msalInstance.acquireTokenRedirect(redirectRequest)
    }
  }
}

export const requestAccessToken = async () => (await requestAccessTokenData())?.accessToken

export const useAuthentication = (where: string) => {
  const { login } = useMsalAuthentication(InteractionType.Silent)
  const { instance, accounts } = useMsal()
  const mainAccount = accounts.length > 0 ? accounts[0] : undefined
  const [getMe] = useGetMeLazyQuery()
  useEffect(() => {
    // call the graphql endpoint to make sure the user account is created
    console.debug(`Calling getMe to create or refresh user account`)
    getMe()
  }, [mainAccount?.username])
  //   console.log(`mainAccount in ${where}: ${mainAccount?.username}`)
  //   if (!mainAccount) console.trace()

  return {
    interactiveLogin: async () => {
      return await login(InteractionType.Redirect, redirectRequest)
    },
    logout: () => {
      apolloClient.resetStore() // clear all local caches
      instance.logout({ postLogoutRedirectUri: '/' })
    },
    mainAccount,
  }
}
