import React, { useReducer, useState } from 'react'
import { useParams, RouteComponentProps } from 'react-router-dom'
import { PlaylistParams } from './PlaylistRoute'
import { useHistory } from 'react-router-dom'
import { InfoField, PropCard, PropToolbar, PropToolbarButton, DropConfirmDialog, PropBlade } from '../shared/CommonUi'
import {
  GetPostAuditRecordFragmentFragment,
  UserRoleInput,
  Role,
  CollectionChrome_UserFragment,
  useGetPlaylistEditQuery,
  GetPlaylistEditFragment,
  useUpdatePlaylistMutation,
} from '../../generated/graphql'
import { getPlaylistPath } from '../../services/routes'
import { gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { assertUnreachable, containsAny, extractPrincipalId, notEmpty } from '../../services/utilities'
import { UserRoleEditor } from '../shared/UserRoleEditor'
import { CollectionChrome } from '../shared/CollectionChrome'
import { NavPage } from '../shared/CollectionHeader'

export const GRAPHQL_RESOURCES = gql`
  fragment getPlaylistAuditRecordFragment on AuditRecord {
    user {
      id
      givenName
      familyName
      userName
    }
    date
  }

  fragment getPlaylistEdit on Playlist {
    id
    title
    myRoles
    created {
      ...getPlaylistAuditRecordFragment
    }
    updated {
      ...getPlaylistAuditRecordFragment
    }
    collection {
      id
      name
      myRoles
      groups {
        id
        name
        __typename
      }
    }
    roleGrants {
      ...userRoleList
    }
  }

  query getPlaylistEdit($collection_id: String!, $playlist_id: String!) {
    getCollection(collection_id: $collection_id) {
      getPlaylist(playlist_id: $playlist_id) {
        ...getPlaylistEdit
      }
    }
    me {
      ...CollectionChrome_User
    }
  }

  mutation updatePlaylist($playlist_id: String!, $input: PlaylistUpdateInput!) {
    updatePlaylist(playlist_id: $playlist_id, input: $input) {
      ...getPlaylistEdit
    }
  }
`

interface StateLoading {
  hasData: false
  hasError: false
}

interface StateLoaded {
  hasData: true
  hasError: false
  playlist: GetPlaylistEditFragment
  me?: CollectionChrome_UserFragment
  clean: GetPlaylistEditFragment
  dirty: Boolean
}

interface StateError {
  hasData: false
  hasError: true
  error: string
}

type State = StateLoading | StateLoaded | StateError

interface ActionLoadData {
  type: 'load_data'
  payload?: { playlist: GetPlaylistEditFragment; me?: CollectionChrome_UserFragment }
}

interface ActionLoadError {
  type: 'load_error'
  payload: string
}

interface ActionUpdateUserRoles {
  type: 'update_userRoles'
  payload: GetPlaylistEditFragment['roleGrants']
}

type Action = ActionLoadData | ActionUpdateUserRoles | ActionLoadError

const reducer = (state: State, action: Action): State => {
  const addDirty = (state: StateLoaded) => ({
    ...state,
    dirty: JSON.stringify(state.playlist) !== JSON.stringify(state.clean),
  })
  console.debug(`dispatch ${action.type}`, action.payload)
  switch (action.type) {
    case 'load_data':
      return action.payload
        ? {
            ...state,
            playlist: action.payload.playlist,
            me: action.payload.me ?? (state.hasData ? state.me : undefined),
            clean: action.payload.playlist,
            dirty: false,
            hasData: true,
            hasError: false,
          }
        : { ...state, hasData: false, hasError: false }
    case 'update_userRoles':
      return state.hasData ? addDirty({ ...state, playlist: { ...state.playlist, roleGrants: action.payload } }) : state
    case 'load_error':
      return { hasData: false, hasError: true, error: action.payload }
  }
  assertUnreachable(action)
  throw new Error(`Unknown state passed to reducer: ${state}`)
}

export function PlaylistEdit(props: RouteComponentProps) {
  const { playlist_id_base, collection_id_base } = useParams<PlaylistParams>()
  const playlist_id = 'pl_' + playlist_id_base
  const collection_id = 'co_' + collection_id_base
  const history = useHistory()
  const [state, dispatch] = useReducer(reducer, { hasData: false, hasError: false })
  const { refetch } = useGetPlaylistEditQuery({
    variables: { collection_id, playlist_id },
    // fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      console.debug(`completed: postEditQuery`)
      console.debug('post get completed', data)
      const inData = data.getCollection?.getPlaylist
      if (inData) dispatch({ type: 'load_data', payload: { playlist: inData, me: data.me ?? undefined } })
    },
    onError: (error) => {
      console.debug(`referch error`, error)
      dispatch({ type: 'load_error', payload: error.message })
    },
  })
  const [updatePlaylistMutation] = useUpdatePlaylistMutation({
    onCompleted: (data) => {
      if (data.updatePlaylist) {
        dispatch({ type: 'load_data', payload: { playlist: data.updatePlaylist } })
      } else {
        dispatch({ type: 'load_error', payload: 'Invalid return value from updatePlaylist mutation' })
      }
    },
  })

  const [isUploading, setIsUploading] = useState(false)
  const [dirtyCancelling, setDirtyCancelling] = useState(false)

  const handleDone = () => {
    if (state.hasData) {
      updatePlaylistMutation({
        variables: {
          playlist_id: state.playlist.id,
          input: {
            roleGrants: state.playlist.roleGrants?.filter(notEmpty).map<UserRoleInput>((ur) => ({
              principal_id: extractPrincipalId(ur?.principal),
              role: ur?.role,
            })),
          },
        },
      })
    }
  }

  const handleCancel = (force: boolean) => {
    if (state.hasData && state.dirty && !force) {
      setDirtyCancelling(true)
    } else {
      history.push(getPlaylistPath(collection_id)(playlist_id))
    }
  }

  const AuditRecordField = ({ label, record }: { label: string; record?: GetPostAuditRecordFragmentFragment | null }) => {
    const userLabel = !record?.user
      ? 'unknown'
      : record.user?.familyName
      ? `${record.user.givenName} ${record.user.familyName}`
      : record.user.userName

    //@ts-ignore
    const dateLabel = !record?.date ? 'unknown' : DateTime.fromISO(record.date).toLocaleString(DateTime.DATETIME_SHORT)
    return record ? <InfoField label={label} value={`${userLabel} at ${dateLabel}`} /> : <div />
  }

  if (!state.hasData) {
    return state.hasError ? <div>Error: {JSON.stringify(state.error, null, ' ')} </div> : <div>Loading...</div>
  }

  const collection = state.playlist.collection
  const me = state.me

  return (
    <CollectionChrome collection={collection} navPage={NavPage.Playlists} me={me ?? null}>
      <>
        <form>
          <PropBlade>
            <PropToolbar>
              <PropToolbarButton onClick={handleDone} className={state.dirty ? '' : 'hidden'}>
                Save Changes
              </PropToolbarButton>
              <PropToolbarButton onClick={() => handleCancel(false)} disabled={isUploading}>
                Close
              </PropToolbarButton>
              <DropConfirmDialog
                visible={dirtyCancelling}
                onCancel={() => setDirtyCancelling(false)}
                onConfirm={() => handleCancel(true)}
                title="Unsaved changes"
                message="Changes will be lost without saving"
              />
            </PropToolbar>
            <PropCard title="Playlist Information">
              <>
                <div></div>
                <div>
                  <InfoField label="id" value={state.playlist.id} />
                  <InfoField label="Title" value={state.playlist.title} />
                  <AuditRecordField label="created" record={state.playlist.created} />
                  <AuditRecordField label="updated" record={state.playlist.updated} />
                </div>
              </>
            </PropCard>
            {containsAny(state.playlist.myRoles, [Role.Admin]) && (
              <PropCard title="Roles">
                {state.playlist.roleGrants && (
                  <UserRoleEditor
                    value={state.playlist.roleGrants ? state.playlist.roleGrants.filter(notEmpty) : []}
                    onChange={(ev) => dispatch({ type: 'update_userRoles', payload: ev })}
                    groupList={state.playlist.collection.groups?.filter(notEmpty)}
                  />
                )}
              </PropCard>
            )}
          </PropBlade>
        </form>
      </>
    </CollectionChrome>
  )
}
