import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { CollectionNav_CollectionFragment, ProfileMenu_UserFragment, Role } from '../../generated/graphql'
import { getCollectionListPath, getCollectionPath, getCollectionPathEdit } from '../../services/routes'
import { containsAny } from '../../services/utilities'
import { HomeIcon } from './CommonUi'
import { NavHeader } from './header/NavHeader'

export interface Props {
  me: ProfileMenu_UserFragment | null
}

export const RootHeader = (props: Props) => {
  return <NavHeader homeText="Video Hub" menuItems={[]} me={props.me} titleHref={getCollectionListPath()} />
}

RootHeader.fragments = {
  user: gql`
    fragment RootHeader_User on User {
      ...ProfileMenu_User
    }
  `,
}
