import { useInterpret, useSelector } from '@xstate/react'
import React, { useState } from 'react'
import { VideoJsPlayer } from 'video.js'
import { PlayerInterpreter, playerMachine, StateType } from './playerState'

import debugModule from 'debug'
const debug = debugModule('videohub:videoPlayerContext')

debug('initializing')

export type SelectorFunction<T> = (state: StateType) => T

export interface VideoPlayerContextType {
  player?: VideoJsPlayer
  setPlayer: (ref: VideoJsPlayer) => void
  // state: StateType
  // send: SendType
  videoInfo?: VideoInfo
  setVideoInfo: (videoInfo?: VideoInfo) => void
  // currentTime: number
  setCurrentTime: (currentTime: number) => void
  stateService: PlayerInterpreter
  useStateSelector: <T>(selector: SelectorFunction<T>) => T
  useGetCurrentTime: () => number
}

export const VideoPlayerContext = React.createContext({} as VideoPlayerContextType)

export const useVideoPlayerContext = () => React.useContext(VideoPlayerContext)

export interface VideoInfo {
  duration: number
}

export const SelectCurrentTime = (state: StateType) => state.context.currentTime

export const VideoPlayerContextProvider: React.FC = ({ children }) => {
  const [player, setPlayer] = React.useState<VideoJsPlayer | undefined>(undefined)
  const [videoInfo, setVideoInfo] = useState<VideoInfo | undefined>(undefined)
  const stateService = useInterpret(playerMachine) // , (state) => debug('state', state))
  const setCurrentTime = (currentTime: number) => {
    stateService.send({ type: 'SET_CURRENT_TIME', currentTime })
  }
  const useGetCurrentTime = () => useSelector(stateService, SelectCurrentTime)

  const useStateSelector = <T extends any>(selector: SelectorFunction<T>) => useSelector(stateService, selector)

  return (
    <VideoPlayerContext.Provider
      value={{ player, setPlayer, stateService, useStateSelector, setCurrentTime, useGetCurrentTime, videoInfo, setVideoInfo }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}
